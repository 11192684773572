import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Variables } from "../../variables/Variables";
import CustomSnipper from "../../components/customSnipper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import { AiOutlineClose } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";
import { BiEdit, BiShowAlt, BiHide } from "react-icons/bi";
import Util from "../../Classes/Util";
import ReactTooltip from "react-tooltip";

function BranchList() {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [loader, setLoader] = useState(false);
  const [branches, setBranches] = useState([]);
  const [filteredBranches, setfilteredBranches] = useState([]);
  const [activeModelShow, setActiveModelShow] = useState(0);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const paginationComponentOptions = {
    rowsPerPageText: "View Records",
    rangeSeparatorText: "out",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  // Get Branches List
  const GetBranchesList = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL + "Branch/GetBranchesForTable",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setBranches(result);
      setfilteredBranches(result);
    }
    setLoader(false);
  };
  const handleSearch = (e) => {
    let value = e.target.value.toLowerCase();
    setSearch(e.target.value);
    let result = [];
    if (value === "") {
      result = branches;
    } else {
      result = branches.filter((child) =>
        child.Name.toLowerCase().includes(value)
      );
    }

    setfilteredBranches(result);
  };
  // Active Deactivate Branch
  const ActiveDeactivateBranch = async (branchId) => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL + "Branch/ToogleActiveBranch/" + branchId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
      GetBranchesList();
      setSearch("");
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }

    setActiveModelShow(0);
    setLoader(false);
  };

  const branchColumns = [
    {
      name: "#",
      selector: (row) => row.BranchId,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => row.CityName,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => (
        <div>{row.IsActive ? <FcCheckmark /> : <AiOutlineClose />}</div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          {permissions.UpdateBranch && (
            <Link
              className="btn btn-sm btn-icon "
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title="Edit"
              to={"/Branch/" + row.BranchId}
            >
              <span className="btn-inner">
                <BiEdit />
              </span>
            </Link>
          )}

          {permissions.ActiveBranch && (
            <button
              className="btn  btn-icon ml-5"
              onClick={() => setActiveModelShow(row.BranchId)}
            >
              {row.IsActive ? (
                <BiHide data-tip="Deactivate" />
              ) : (
                <BiShowAlt data-tip="Activate" />
              )}
            </button>
          )}
          <ReactTooltip place="bottom" className="tooltip" effect="solid" />
          <Modal
            show={row.BranchId === activeModelShow}
            onHide={() => setActiveModelShow(0)}
          >
            <Modal.Header closeButton>
              <Modal.Title as="h5">
                {row.IsActive ? "Deactivate Branch" : "Activate Branch"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to{" "}
              {row.IsActive ? "Deactivate : " : "Avtivate : "}
              Branch {row.Name}?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setActiveModelShow(0)}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => ActiveDeactivateBranch(row.BranchId)}
              >
                Save changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];
  useEffect(() => {
    GetBranchesList();
  }, []);

  return (
    <>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Branches List</h4>
              </div>
              {permissions.AddBranch && (
                <Button
                  variant="btn buttonMeatcity"
                  onClick={() => navigate("/Branch/0")}
                >
                  Add New Branch
                </Button>
              )}
            </Card.Header>

            <Card.Body>
              <Row style={{ marginBottom: "20px" }}>
                <Col sm="6" md="4" lg="4" className="text-right">
                  <input
                    type="text"
                    onChange={(e) => handleSearch(e)}
                    className="form-control"
                    autoFocus={true}
                    placeholder="Search By Name"
                    value={search}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  {loader ? (
                    <CustomSnipper />
                  ) : (
                    <DataTable
                      columns={branchColumns}
                      data={filteredBranches}
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                    />
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default BranchList;
