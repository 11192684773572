import React,{useState,useEffect} from 'react';
import { Row, Col, Card, Button } from "react-bootstrap";
import CustomSnipper from "../../../components/customSnipper";
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Variables } from '../../../variables/Variables';

const ManageUser = () => {
    let {UserId}=useParams()
    const navigate=useNavigate()
    const[loader,setLoader]=useState(false);
    const[user,setUser]=useState({Username:"",Email:"",FirstName:"",LastName:"",PhoneNumber:"",RoleId:0});
    const[roles,setRoles]=useState([])
    const PhoneNumberChange = async (e) => {
        if (e !== undefined) {
          if (isPossiblePhoneNumber(e)) {
            setUser((prevState) => ({
              ...prevState,
              PhoneNumber: e,
            }));
        }
    }
      };
  // Get User
  const fetchUser = async () => {
    let response = await fetch(
      Variables.API_URL + "User/GetUser/" + UserId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
     setUser({
         Username:result.Username,
         Email:result.Email,
         FirstName:result.FirstName,
         LastName:result.LastName,
         PhoneNumber:result.PhoneNumber,
         RoleId:result.RoleID
         
     })
    }
  };

      
  // Get Roles
  const GetRoles = async () => {
    let response = await fetch(Variables.API_URL + "Role/GetRoles", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setRoles(result);
    }
  };

function IsValid(){
    const EmailRegex = new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$");
    var validEmail = EmailRegex.test(user.Email);
    if(user.FirstName===""){
        toast.error("First Name Is Required");
        return false;

    }
    else if(user.LastName===""){
        toast.error("Last Name Is Required");
        return false;
    }
    else if(user.Username===""){
        toast.error("Username Is Required");
        return false;
    }else if (user.Email === "") {
        toast.error("Email Is Required");
        return false;
      }
      else if(!validEmail){
        toast.error("Invalid email")
        return false;
      } else if (user.PhoneNumber === "") {
        toast.error("Phone Number Required");
        return false;
      }
      else if (!isPossiblePhoneNumber(user.PhoneNumber)) {
        toast.error("Please enter a valid phone number");
        return false;
      }
      else if(user.RoleId==0){
        toast.error("Role Required");
        return false;
      }
      return true;
}
  const Submit = async () => {
    if (IsValid()) {
      if (UserId == 0) {
        let response = await fetch(Variables.API_URL + "User/UserRegistration", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Variables.HostAPI,
          },
          body: JSON.stringify({
            FirstName: user.FirstName,
            LastName: user.LastName,
            Email: user.Email,
            PhoneNumber: user.PhoneNumber,
            RoleId:user.RoleId,
            Username:user.Username
          }),
        });
        if (response.ok === false) {
          response = await response.json();
          toast.error(response);
        } else {
          toast.success("Successfully Added");
          setTimeout(() => {
            navigate("/Users");
          }, 1000);
        }
      } else {
        let response = await fetch(
          Variables.API_URL + "User/UpdateUser/" + UserId,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Variables.HostAPI,
            },
            body: JSON.stringify({
                FirstName: user.FirstName,
            LastName: user.LastName,
            Email: user.Email,
            PhoneNumber: user.PhoneNumber,
            RoleId:user.RoleId,
            Username:user.Username
            }),
          }
        );
        if (response.ok === false) {
          response = await response.json();
          toast.error(response);
        } else {
          toast.success("Successfully Updated");
          setTimeout(() => {
            navigate("/Users");
          }, 1000);
        }
      }
    }
  };



  useEffect(() => {
      if(UserId!=0){
          fetchUser()
      }
    GetRoles();
  }, []);

  return (
    <>
    <Row>
      <Col xl="12" lg="12">
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Manage User</h4>
            </div>
            <div>
              <Button
                variant="secondary"
                onClick={() => navigate("/Users")}
              >
                Cancel
              </Button>
              {"  "}
              <Button variant="btn buttonMeatcity" 
              onClick={Submit}
              >
                Submit
              </Button>
            </div>
          </Card.Header>
          {loader === true ? (
            <CustomSnipper />
          ) : (
            <Card.Body>
              <div className="bd-example">
              
                <Row>
            <Col lg="6" md="6" sm="12">
                <label> First Name</label>
                <input type="text" value={user.FirstName}  onChange={(e) =>
                          setUser((prevState) => ({
                            ...prevState,
                            FirstName: e.target.value,
                          }))
                        }
                        className="form-control" placeholder='First Name'/>
            </Col>
            <Col lg="6" md="6" sm="12">
                <label> Last Name</label>
                <input type="text" value={user.LastName}  onChange={(e) =>
                          setUser((prevState) => ({
                            ...prevState,
                            LastName: e.target.value,
                          }))
                        }
                        className="form-control" placeholder='Last Name'/>
            </Col>
                </Row>
                <Row>
            <Col lg="6" md="6" sm="12">
                <label> Username</label>
                <input type="text" value={user.Username}  onChange={(e) =>
                          setUser((prevState) => ({
                            ...prevState,
                            Username: e.target.value,
                          }))
                        }
                        className="form-control" placeholder='Username'/>
            </Col>
            <Col lg="6" md="6" sm="12">
                <label> Email</label>
                <input type="text" value={user.Email}  onChange={(e) =>
                          setUser((prevState) => ({
                            ...prevState,
                            Email: e.target.value,
                          }))
                        }
                        className="form-control" placeholder='Email'/>
            </Col>
                </Row>
                <Row>
            <Col lg="6" md="6" sm="12">
                <label> Phone Number</label>
                <PhoneInput
                        placeholder="Phone Number"
                        country="AE"
                        defaultCountry="AE"
                        className="phoneValidate"
                        value={user.PhoneNumber}
                        countryCallingCodeEditable={false}
                        onChange={PhoneNumberChange}
                        international = {false}
                        required={true}
                      />
            </Col>
            <Col lg="6" md="6" sm="12">
                <label> Role</label>
                <select
                                    className="form-control"
                                    name="RoleId"
                                    id="RoleId"
                                    value={user.RoleId}
                                    onChange={(e) =>
                                        setUser((prevState) => ({
                                          ...prevState,
                                          RoleId: e.target.value,
                                        }))
                                      }
                                  >
                                    <option key={0} value={0}>
                                      -- Select Role --
                                    </option>
                                    {roles.map((x) => {
                                      return (
                                        <option key={x.RoleId} value={x.RoleId}>
                                          {x.RoleName}
                                        </option>
                                      );
                                    })}
                                  </select>
            </Col>
                </Row>

            
                
              </div>
            </Card.Body>
          )}
        </Card>
      </Col>
    </Row>
    <ToastContainer
      position="top-right"
      autoClose={1000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </>
  )
}

export default ManageUser