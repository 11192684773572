import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Tab,
  Nav,
  Modal,
  Button,
  Spinner,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Util from "../../Classes/Util";
import { Variables } from "../../variables/Variables";
import { Link } from "react-router-dom";
import CustomSnipper from "../../components/customSnipper";
function ListOrders() {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [userBranches, setUserBranches] = useState([]);
  const [orders, setOrders] = useState([]);
  const [orderStatus, SetOrderStatus] = useState([]);
  const [ordersLoader, setOrdersLoader] = useState(false);
  const [orderDetailsModal, setOrderDetailsModal] = useState(0);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [orderToUpdate, setOrderToUpdate] = useState({
    OrderId: 0,
    OrderStatusId: 0,
    DeliveryCompanyId:0
  });
  const [updateLoader, setUpdateLoader] = useState(false);
  const [deliveryCompanies, setDeliveryCompanies] = useState([]);

  const [selectedDeliveryCompanyId, setSelectedDeliveryCompanyId] = useState(0);

  // Get User Branches
  const GetUserBranches = async () => {
    let response = await fetch(
      Variables.API_URL +
        "User/GetUserBranches/" +
        Util.decrypt(localStorage.getItem("UserId")),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setUserBranches(result);
    }
  };

  // Get Delivery Companies
  const GetDeliveryCompanies = async () => {
    let response = await fetch(
      Variables.API_URL + "DeliveryCompany/GetDeliveryCompanies",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setDeliveryCompanies(result);
    }
  };

  // Get orders Stautses
  const GetOrdersStatus = async () => {
    let response = await fetch(
      Variables.API_URL + "Order/GetAllOrderStatuses",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      SetOrderStatus(result);
    }
  };

  // Get orders
  const GetOrders = async () => {
    let response = await fetch(
      Variables.API_URL +
        "Order/GetUserAssignedBranchesOrders/" +
        Util.decrypt(localStorage.getItem("UserId")),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setOrders(result);
      setFilteredData(result)
    }
  };

  const SaveChanges = async (e) => {
    debugger;
      if(orderToUpdate.OrderStatusId!=0){
        if(orderToUpdate.OrderStatusId!=3){
      let response = await fetch(Variables.API_URL + "Order/ChangeOrderStatus", {
        method: "Put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
         OrderId:orderToUpdate.OrderId,
         OrderStatusId:orderToUpdate.OrderStatusId
        }),
      });
      let result = await response.json();
      if (response.status === 200) {
        toast.success(result);
        setOrderToUpdate({
          OrderId: 0,
          OrderStatusId: 0,
          DeliveryCompanyId:0
        });
        setOrderDetailsModal(0);
        GetOrders();
      } else {
        toast.error(result);
      }
        }
        if(orderToUpdate.OrderStatusId==3){
          let response = await fetch(Variables.API_URL + "Order/ChangeOrderStatusWithDeliveryCompany", {
            method: "Put",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
             OrderId:orderToUpdate.OrderId,
             OrderStatusId:orderToUpdate.OrderStatusId,
             DeliveryCompanyId:orderToUpdate.DeliveryCompanyId
            }),
          });
          let result = await response.json();
          if (response.status === 200) {
            toast.success(result);
            setOrderToUpdate({
              OrderId: 0,
              OrderStatusId: 0,
              DeliveryCompanyId:0
            });
            setOrderDetailsModal(0);
            GetOrders();
          } else {
            toast.error(result);
          }
            }
       
}else{
  toast.error("Please Choose A Status")
}

  };
  // Cancel Update
  const CancelUpdate = () => {
    setOrderToUpdate({
      OrderId: 0,
      OrderStatusId: 0,
      DeliveryCompanyId:0
    });
    setOrderDetailsModal(0);
  };



  useEffect(() => {
    GetDeliveryCompanies();
    GetUserBranches();
    GetOrdersStatus();
    GetOrders();
  }, []);

  return (
    <div>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Orders</h4>
              </div>
             
            </Card.Header>
            <Card.Body className="px-3">
              <div className="bd-example">
                <Tab.Container defaultActiveKey="defaultx">
                  <Nav
                    variant="tabs"
                    className="mb-3"
                    id="nav-tab"
                    role="tablist"
                  >
                    {userBranches.map((data, x) => (
                      <Nav key={x + "nav"}>
                        <Nav.Link
                          key={x + "nav-link"}
                          eventKey={
                            x === 0
                              ? "defaultx"
                              : data.BranchName.replace(" ", "")
                          }
                          variant=""
                          id={
                            "nav-" + data.BranchName.replace(" ", "") + "-tab"
                          }
                          data-bs-toggle="tab"
                          data-bs-target={
                            "#nav-" + data.BranchName.replace(" ", "")
                          }
                          type="button"
                          role="tab"
                          aria-controls={
                            "nav-" + data.BranchName.replace(" ", "")
                          }
                          aria-selected="false"
                        >
                          {data.BranchName + "  "}{" "}
                          {orders.filter((o) => o.BranchId == data.BranchId)
                            .length > 0 ? (
                            <span className="badge rounded-pill bg-primary">
                              {
                                orders.filter(
                                  (o) => o.BranchId == data.BranchId && o.OrderStatusId==1
                                ).length
                              
                              }
                            </span>
                          ) : (
                            " "
                          )}
                        </Nav.Link>
                      </Nav>
                    ))}
                  </Nav>
                  <Tab.Content>
                    {updateLoader === true && (
                      <Row>
                        <Col className="allignCenter">
                          <Spinner
                            animation="border"
                            variant="primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </Col>
                      </Row>
                    )}

                    {userBranches.map((data, i) => (
                      <Tab.Pane
                        key={i + "tab-pane"}
                        className=" fade"
                        id={"nav-" + data.BranchName.replace(" ", "")}
                        eventKey={
                          i === 0
                            ? "defaultx"
                            : data.BranchName.replace(" ", "")
                        }
                        role="tabpanel"
                        aria-labelledby={
                          "nav-" + data.BranchName.replace(" ", "") + "-tab"
                        }
                      >
                        {ordersLoader === true ? (
                          <CustomSnipper />
                        ) : (
                          <Tab.Container
                            defaultActiveKey={"default"}
                            key={i + "xx"}
                          >
                            <Nav className="mt-5">
                              {orderStatus.map((status, x) => (
                                <Nav
                                  key={x + "nav1"}
                                  variant="tabs"
                                  className="mb-3"
                                  id="nav-tab"
                                  role="tablist"
                                >
                                  <Nav.Link
                                    eventKey={
                                      x === 0 ? "default" : status.StatusName
                                    }
                                    variant=" d-flex align-items-center"
                                    id={"nav-" + status.StatusName + "-tab"}
                                    data-bs-toggle="tab"
                                    data-bs-target={"#nav-" + status.StatusName}
                                    type="button"
                                    role="tab"
                                    aria-controls={"nav-" + status.StatusName}
                                    aria-selected="true"
                                  >
                                    {status.StatusName + " "}{" "}
                                    <span className="badge rounded-pill bg-primary">
                                      {
                                        orders.filter(
                                          (o) =>
                                            o.OrderStatusId ===
                                              status.OrderStatusId &&
                                            o.BranchId === data.BranchId
                                        ).length
                                      }
                                    </span>
                                  </Nav.Link>
                                </Nav>
                              ))}
                            </Nav>
                            <Tab.Content>
                              {orderStatus.map((status, x) => (
                                <Tab.Pane
                                  key={x + "tab-pane1"}
                                  className=" fade show"
                                  eventKey={
                                    x === 0 ? "default" : status.StatusName
                                  }
                                  id={"#nav-" + status.StatusName}
                                  role="tabpanel"
                                  aria-labelledby={
                                    "nav-" + status.StatusName + "-tab"
                                  }
                                >
                                  <table
                                    key={x + "table"}
                                    id="user-list-table"
                                    className="table table-striped"
                                    role="grid"
                                    data-toggle="data-table"
                                  >
                                    <thead>
                                      <tr className="ligth">
                                        <th
                                        className="text-center"
                                          onClick={() =>
                                            setOrderDetailsModal(true)
                                          }
                                        >
                                          Order#
                                        </th>
                                        <th  className="text-center">Customer</th>
                                        <th  className="text-center">Payment Method</th>
                                        <th  className="text-center">Order Date</th>
                                        <th  className="text-center">Price</th>
                                        <th min-width="100px"  className="text-center">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {filteredData
                                        .filter(
                                          (x) =>
                                            x.BranchId === data.BranchId &&
                                            x.OrderStatusId ===
                                              status.OrderStatusId
                                        )
                                        .map((item, idx) => (
                                          <tr key={idx + "tr"}>
                                            <td className="text-center">
                                              {item.OrderNumber}
                                            </td>
                                            <td  className="text-center">{item.CustomerName}</td>
                                            <td  className="text-center">{item.PaymentMethodName}</td>
                                            <td  className="text-center">{item.Date}</td>
                                            <td  className="text-center">
                                              {item.OrderFinalPriceWithDelivery}
                                            </td>
                                            <td  className="text-center">
                                              {permissions.UpdateOrder && (
                                                <Link
                                                  key={idx + "link-td"}
                                                  className="btn btn-sm btn-icon btn-success"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title=""
                                                  data-original-title="Edit"
                                                  to={"#"}
                                                  onClick={() =>
                                                    setOrderDetailsModal(
                                                      item.OrderId
                                                    )
                                                  }
                                                >
                                                  <span className="btn-inner">
                                                    <svg
                                                      width="20"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                      <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                      <path
                                                        d="M15.1655 4.60254L19.7315 9.16854"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                    </svg>
                                                  </span>
                                                </Link>
                                              )}
                                              <Modal
                                                key={idx}
                                                show={
                                                  orderDetailsModal ===
                                                  item.OrderId
                                                }
                                                size="xl"
                                                aria-labelledby="contained-modal-title-vcenter"
                                                animation={true}
                                                autoFocus={true}
                                                keyboard={true}
                                                scrollable={true}
                                                backdrop="static"
                                                //onHide={() => GetOrders()}
                                              >
                                                <Modal.Header>
                                                  <Modal.Title>
                                                    {"Order# : " +
                                                      item.OrderNumber}
                                                  </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                  <Row className="mb-5">
                                                    <Col md="3">
                                                      {"Customer : " +
                                                        item.CustomerName}
                                                    </Col>
                                                    <Col md="3">
                                                      {"Payment : " +
                                                        item.PaymentMethodName}
                                                    </Col>
                                                    <Col md="3">
                                                      {"Total Price : " +
                                                        item.OrderFinalPriceWithoutDelivery}
                                                    </Col>
                                                    <Col md="3">
                                                    {orderStatus[x + 1] != null && orderStatus[x].OrderStatusId!=4 && orderStatus[x].OrderStatusId!=5  ? (
                                                       <select className="form-control" value={orderToUpdate.OrderStatusId} onChange={(e)=>setOrderToUpdate({OrderId:item.OrderId,OrderStatusId:e.target.value,DeliveryCompanyId:item.DeliveryCompanyId})}>
                                                        <option value={0}>--Select--</option>

                                                     {orderStatus.filter(child=> child.OrderStatusId!=item.OrderStatusId && child.OrderStatusId>item.OrderStatusId && child.OrderStatusId!=5).map((o,i)=>{
                                                      return(
                                                        <option value={o.OrderStatusId} > {o.StatusName}</option>
                                                      )
                                                     })
                                                     
                                                     }
                                                       </select>
                                                      ) : (
                                                        <input
                                                          type="text"
                                                          disabled={true}
                                                          value={
                                                            orderStatus[x]
                                                              .StatusName
                                                          }
                                                          className="form-control"
                                                        />
                                                      )}
                                                    </Col>
                                                    <Form.Group className="col-md-3 form-group">
                                                      <Form.Label htmlFor="fname">
                                                        Delivery Company:
                                                      </Form.Label>
                                                      <select
                                                        className="form-control"
                                                        name="CompanyId"
                                                        id="CompanyId"
                                                        disabled={
                                                         orderToUpdate.OrderStatusId!=3 ?  true :false
                                                        }
                                                        value={
                                                          item.DeliveryCompanyId
                                                        }
                                                        defaultValue={
                                                          item.DeliveryCompanyId
                                                        }
                                                        onChange={(e) =>
                                                         { setOrderToUpdate({OrderId:item.OrderId,OrderStatusId:orderToUpdate.OrderStatusId,DeliveryCompanyId:e.target.value});item.DeliveryCompanyId=e.target.value}
                                                        }
                                                      >
                                                        <option
                                                          key={0}
                                                          value={0}
                                                        >
                                                          -- Delivery Company --
                                                        </option>
                                                        {deliveryCompanies.map(
                                                          (x) => {
                                                            return (
                                                              <option
                                                                key={
                                                                  x.DeliveryCompanyId
                                                                }
                                                                value={
                                                                  x.DeliveryCompanyId
                                                                }
                                                              >
                                                                {x.Name}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                      </select>
                                                    </Form.Group>
                                                  </Row>
                                                  {item.OrderDetails.map(
                                                    (detail, index) => (
                                                      <>
                                                        <Row
                                                          key={index + "row"}
                                                          style={{
                                                            backgroundColor:
                                                              "whitesmoke",
                                                          }}
                                                        >
                                                          <Col md="3">
                                                            <b>
                                                              {"Item : " +
                                                                (index + 1) +
                                                                " - " +
                                                                detail
                                                                  .ProductDetails
                                                                  .ProductName}{" "}
                                                              :
                                                            </b>
                                                            <hr className="mt-2" />
                                                            <img
                                                              src={
                                                                Variables.ImagePath +
                                                                detail
                                                                  .ProductDetails
                                                                  .ImageUrl
                                                              }
                                                              className="w-100 shadow-1-strong rounded mb-4 img-200 image-log"
                                                              alt="Boat on Calm Water"
                                                            />
                                                          </Col>
                                                          <Col md="9">
                                                            <Row className="p-3">
                                                              <Col md="4">
                                                                <h6>
                                                                  Quantity :{" "}
                                                                  {
                                                                    detail.Quantity
                                                                  }
                                                                </h6>
                                                              </Col>
                                                              <Col md="4">
                                                                <h6>
                                                                  Size :{" "}
                                                                  {
                                                                    detail.SizeName
                                                                  }
                                                                </h6>
                                                              </Col>
                                                              <Col md="4">
                                                                <h6
                                                                  style={{
                                                                    float:
                                                                      "right",
                                                                  }}
                                                                >
                                                                  Total Item
                                                                  Price :{" "}
                                                                  {
                                                                    detail.TotalPriceItem
                                                                  }
                                                                </h6>
                                                              </Col>
                                                            </Row>

                                                            <Row className="p-3 mt-2">
                                                              <Col md="4">
                                                                <h6>
                                                                  Without :
                                                                </h6>
                                                                <hr className="mt-2" />
                                                                <ul>
                                                                  {detail
                                                                    .UnrequiredIngredients
                                                                    .length ===
                                                                  0
                                                                    ? "No Ingredients"
                                                                    : detail.UnrequiredIngredients.map(
                                                                        (
                                                                          unreq,
                                                                          o
                                                                        ) => (
                                                                          <li
                                                                            key={
                                                                              o
                                                                            }
                                                                          >
                                                                            {
                                                                              unreq.Name
                                                                            }
                                                                          </li>
                                                                        )
                                                                      )}
                                                                </ul>
                                                              </Col>
                                                              <Col md="4">
                                                                <h6>
                                                                  Addons :
                                                                </h6>
                                                                <hr className="mt-2" />
                                                                <ul>
                                                                  {detail.AddOns
                                                                    .length ===
                                                                  0
                                                                    ? "No AddOns"
                                                                    : detail.AddOns.map(
                                                                        (
                                                                          unreq,
                                                                          o
                                                                        ) => (
                                                                          <li
                                                                            key={
                                                                              o
                                                                            }
                                                                          >
                                                                            {
                                                                              unreq.Name +" + "+unreq.ExtraPrice+"$"
                                                                            }
                                                                          </li>
                                                                        )
                                                                      )}
                                                                </ul>
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                        </Row>
                                                        <hr />
                                                      </>
                                                    )
                                                  )}
                                                </Modal.Body>
                                                <Modal.Footer>
                                                  <Button
                                                    variant="secondary"
                                                    onClick={() =>
                                                      CancelUpdate()
                                                    }
                                                  >
                                                    Close
                                                  </Button>
                                                  <Button
                                                    variant="primary"
                                                    onClick={(e) => SaveChanges()}
                                                  >
                                                    Save changes
                                                  </Button>
                                                </Modal.Footer>
                                              </Modal>
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>

                                  {orders.filter(
                                    (x) =>
                                      x.BranchId === data.BranchId &&
                                      x.OrderStatusId === status.OrderStatusId
                                  ).length === 0 && (
                                    <Row>
                                      <Col
                                        className="Col-Text-Center"
                                        style={{
                                          backgroundColor: "#c2ced93b",
                                          height: "120px",
                                        }}
                                      >
                                        <h6 className="mt-5">
                                          No Orders in {status.StatusName}
                                        </h6>
                                      </Col>
                                    </Row>
                                  )}
                                </Tab.Pane>
                              ))}
                            </Tab.Content>
                          </Tab.Container>
                        )}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>

              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ListOrders;
