import React from "react";
import { GrLanguage } from "react-icons/gr";
import { Link } from "react-router-dom";
import { AiOutlineUserAdd } from "react-icons/ai";
import { FcSupport } from "react-icons/fc";
import Util from "../../Classes/Util";
const Settings = () => {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  return (
    <div className="container-fluid">
      <div className="col-12">
        <div className="row">
          {permissions.ViewLanguages && (
            <div className="col-4">
              <Link aria-current="page" to="/Languages">
                <div className="card ">
                  <div className="card-body">
                    <span className="settingsIcons">
                      {" "}
                      <GrLanguage /> Languages{" "}
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          )}
          {permissions.ViewUsers && (
            <div className="col-4">
              <Link aria-current="page" to="/Users">
                <div className="card ">
                  <div className="card-body">
                    <span className="settingsIcons">
                      {" "}
                      <AiOutlineUserAdd /> Users{" "}
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          )}
        </div>
        <div className="row">
          {permissions.ViewRoles && (
            <div className="col-4">
              <Link aria-current="page" to="/Roles">
                <div className="card ">
                  <div className="card-body">
                    <span className="settingsIcons">
                      {" "}
                      <FcSupport /> Roles & Permissions{" "}
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;
