import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate, Link } from "react-router-dom";
import { Variables } from "../../variables/Variables";
import { BiEdit, BiShowAlt, BiHide } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import { FaStoreSlash, FaStore } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";
import ReactTooltip from "react-tooltip";
import CustomSnipper from "../../components/customSnipper";
import Util from "../../Classes/Util";
const Products = () => {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  //Publish/Unpublish products
  const TooglePublish = async (productId) => {
    let response = await fetch(
      Variables.API_URL + "Product/TooglePublishProduct/" + productId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    if (response.ok === false) {
      toast.error(result);
    } else {
      toast.success(result);
      setSearch("");
      GetProducts();
    }
  };

  //set in stock/out of stock
  const ToogleStockAvailability = async (productId) => {
    let response = await fetch(
      Variables.API_URL + "Product/ToogleStockAvailability/" + productId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    if (response.ok === false) {
      toast.error(result);
    } else {
      toast.success(result);
      GetProducts();
      setSearch("");
    }
  };
  //fetch products

  const GetProducts = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL + "Product/GetProductsForTable",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setProducts(result);
      setFilteredProducts(result);
      setLoader(false);
    }
  };
  //handle Search by  Name
  const handleSearch = (e) => {
    setSearch(e.target.value);
    let value = e.target.value.toLowerCase();
    let result = [];
    if (value === "") {
      result = products;
    } else {
      result = products.filter((child) =>
        child.Name.toLowerCase().includes(value)
      );
    }

    setFilteredProducts(result);
  };

  //Pagination
  const paginationComponentOptions = {
    rowsPerPageText: "View Records",
    rangeSeparatorText: "out",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };
  //products columns

  const productsColumns = [
    {
      name: "#",
      selector: (row) => row.ProductId,
      sortable: true,
    },
    {
      name: "Product Name",
      selector: (row) => row.Name,
      sortable: true,
    },
    // {
    //   name: "Regular Price",
    //   selector: (row) => row.RegularPrice,
    //   sortable: true,
    // },
    // {
    //   name: "Sale Price",
    //   selector: (row) => row.SalePrice,
    //   sortable: true,
    // },
    {
      name: "Product Image",
      selector: (row) => (
        <div>
          <img
            src={Variables.ImagePath + row.ProductImage}
            className="productImage"
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: "Stock Availability",
      selector: (row) => (
        <div>{row.InStock ? <FcCheckmark /> : <AiOutlineClose />}</div>
      ),
      sortable: true,
    },
    {
      name: "Publish",
      selector: (row) => (
        <span
          className={
            row.IsPublished === false ? "badge bg-danger" : "badge bg-success"
          }
        >
          {row.IsPublished === true ? "Published" : "Unpublished"}
        </span>
      ),
      sortable: true,
    },

    {
      name: "Actions",
      selector: (row) => (
        <div>
          {permissions.UpdateProduct && (
            <Link
              className="btn btn-sm btn-icon "
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title="Edit"
              to={"/Products/" + row.ProductId}
            >
              <span className="btn-inner">
                <BiEdit data-tip={"Manage " + row.Name} />
              </span>
              <ReactTooltip place="bottom" className="tooltip" effect="solid" />
            </Link>
          )}
          {permissions.ToggleStockProduct && (
            <button
              className="btn  btn-icon  mr-5"
              onClick={() => ToogleStockAvailability(row.ProductId)}
            >
              {row.InStock ? (
                <FaStoreSlash data-tip="Set Out Of Stock" />
              ) : (
                <FaStore data-tip="Set In Stock" />
              )}
            </button>
          )}
          {permissions.PublishProduct && (
            <button
              className="btn  btn-icon ml-5"
              onClick={() => TooglePublish(row.ProductId)}
            >
              {row.IsPublished ? (
                <BiHide data-tip="Unpublish" />
              ) : (
                <BiShowAlt data-tip="Publish" />
              )}
            </button>
          )}
        </div>
      ),
    },
  ];
  //#region Use Effect
  useEffect(() => {
    GetProducts();
  }, []);

  //#endregion Use Effect
  return (
    <>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Products List</h4>
              </div>
              {permissions.AddProduct && (
                <Button
                  variant="btn buttonMeatcity"
                  onClick={() => navigate("/Products/0")}
                >
                  Add New Product
                </Button>
              )}
            </Card.Header>

            <Card.Body>
              <Row style={{ marginBottom: "20px" }}>
                <Col sm="6" md="4" lg="4" className="text-right">
                  <input
                    type="text"
                    onChange={(e) => handleSearch(e)}
                    className="form-control"
                    autoFocus={true}
                    placeholder="Search By Name"
                    value={search}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  {loader ? (
                    <CustomSnipper />
                  ) : (
                    <DataTable
                      columns={productsColumns}
                      data={filteredProducts}
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                    />
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default Products;
