import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Variables } from "../../variables/Variables";
import CustomSnipper from "../../components/customSnipper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import {AiOutlineClose} from 'react-icons/ai'
import {FcCheckmark} from 'react-icons/fc';
import { BiEdit, BiShowAlt, BiHide } from 'react-icons/bi';
import ReactTooltip from "react-tooltip";
import Util from "../../Classes/Util";
function Sizes() {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [loader, setLoader] = useState(false);
  const [sizes, setsizes] = useState([]);
  const[filteredsizes,setfilteredsizes]=useState([])
  const [activeModelShow, setActiveModelShow] = useState(0);
  const[search,setSearch]=useState("")
  const navigate = useNavigate();
  const paginationComponentOptions = {
    rowsPerPageText: "View Records",
    rangeSeparatorText: "out",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };


  // Get sizes List
  const GetsizesList = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL +
        "Size/GetAllSizes",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setsizes(result);
      setfilteredsizes(result)
    }
    setLoader(false);
  };
  const handleSearch=(e)=>{
    let value = e.target.value.toLowerCase();
    setSearch(e.target.value)
         let result = [];
         if(value===""){
           result=sizes
         }
         else{
           result=sizes.filter(child=>child.Name.toLowerCase().includes(value))
         }
     
         setfilteredsizes(result)
   }

  const sizesColumns = [
    {
      name: "#",
      selector: (row) => row.SizeId,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
    },
    {
      name: "Suffix",
      selector: (row) => row.Suffix,
      sortable: true,
    },
    {
        name: "Publish",
        selector: (row) => (
          <span
            className={
              row.IsPublished === false ? "badge bg-danger" : "badge bg-success"
            }
          >
            {row.IsPublished === true ? "Published" : "Unpublished"}
          </span>
        ),
        sortable: true,
      },
    {
      name: "Actions",
      selector: (row) => (
        <div>
    {permissions.UpdateSize && (        <Link
                                  className="btn btn-sm btn-icon "
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Edit"
                                  to={"/Sizes/"+row.SizeId}
                                >
                                  <span className="btn-inner">
                                    <BiEdit/>
                                  </span>
                                </Link>)}

                               {permissions.TogglePublishSize && (
                               <button  className="btn  btn-icon ml-5" onClick={()=>setActiveModelShow(row.SizeId)}>
                              {row.IsPublished? <BiHide data-tip="Unpublish"/> : <BiShowAlt data-tip="Publish"/>}
                              </button>
                              )} 
                              <ReactTooltip
                      place="bottom"
                      className="tooltip"
                      effect="solid"
                    /> 
               <Modal
                                show={row.SizeId === activeModelShow}
                                onHide={() => setActiveModelShow(0)}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title as="h5">
                                    {row.IsPublished
                                      ? "Unpublish size"
                                      : "Publish size"}
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  Are you sure you want to{" "}
                                  {row.IsPublished
                                    ? "Unpublish : "
                                    : "Publish : "}
                                 The Size {row.Name}?
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={() => setActiveModelShow(0)}
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    variant="btn buttonMeatcity"
                                    onClick={() =>
                                      TogglePublish(row.SizeId)
                                    }
                                  >
                                    Save changes
                                  </Button>
                                </Modal.Footer>
                              </Modal>
        </div>
      ),
    },
  ];
  // Active Deactivate Branch
  const TogglePublish = async (SizeId) => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL +
        "Size/TogglePublish/"+SizeId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
      GetsizesList();
      setSearch("");
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
   
    setActiveModelShow(0);
    setLoader(false);
  };
  useEffect(() => {
    GetsizesList()
  },[])


  return (
    <>
    <Row>
      <Col xl="12" lg="12">
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Sizes List</h4>
            </div>
          {permissions.AddSize && (
           <Button variant="btn buttonMeatcity" onClick={() => navigate("/Sizes/0")}>Add New Size</Button>
           )} 
          </Card.Header>
        
            <Card.Body>
    <Row style={{marginBottom:"20px"}}>
      <Col sm="6" md="4" lg="4" className="text-right">
        <input type="text" onChange={(e)=>handleSearch(e)} className="form-control" autoFocus={true} placeholder="Search By Name" value={search}/>
      </Col>

    </Row>

    <Row>
      <Col sm="12">
      {loader ? (
                <CustomSnipper />
              ) : (
                <DataTable
                  columns={sizesColumns}
                  data={filteredsizes}
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                />
              )}
      </Col>
    </Row>

            
            </Card.Body>
     
        </Card>
      </Col>
    </Row>
    <ToastContainer
      position="top-right"
      autoClose={1000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </>
  )
}

export default Sizes