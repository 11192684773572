//router
import IndexRouters from "./router/index";
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { Button } from "react-bootstrap";
//header
import Header from "./components/partials/dashboard/HeaderStyle/header";
//subheader
import SubHeader from "./components/partials/dashboard/HeaderStyle/sub-header";
import Sidebar from "./components/partials/dashboard/SidebarStyle/sidebar";
import Footer from "./components/partials/dashboard/FooterStyle/footer";
import PrivateRoutes from "./variables/PrivateRoutes";
import Loader from "./components/Loader";
import PublicRoutes from "./variables/PublicRoutes";
import { Variables } from "./variables/Variables";

// store
import {
  NavbarstyleAction,
  getDirMode,
  getcustomizerMode,
  getcustomizerprimaryMode,
  getcustomizerinfoMode,
  SchemeDirAction,
  ColorCustomizerAction,
  getNavbarStyleMode,
  getSidebarActiveMode,
  SidebarActiveStyleAction,
  getDarkMode,
  ModeAction,
  SidebarColorAction,
  getSidebarColorMode,
  getSidebarTypeMode,
} from "./store/setting/setting";
import { connect } from "react-redux";

//scss
import "./assets/scss/hope-ui.scss";
import "./assets/scss/dark.scss";
import "./assets/scss/rtl.scss";
import "./assets/scss/custom.scss";
import "./assets/scss/customizer.scss";
import Util from "./Classes/Util";

// const mapStateToProps = (state) => {
//   return {
//     darkMode: getDarkMode(state),
//     customizerMode: getcustomizerMode(state),
//     cololrinfomode: getcustomizerinfoMode(state),
//     colorprimarymode: getcustomizerprimaryMode(state),
//     schemeDirMode: getDirMode(state),
//     sidebarcolorMode: getSidebarColorMode(state),
//     sidebarTypeMode: getSidebarTypeMode(state),
//     sidebaractivestyleMode: getSidebarActiveMode(state),
//     navbarstylemode: getNavbarStyleMode(state),
//   };
// };
// const mapDispatchToProps = (dispatch) => ({
//   ...bindActionCreators(
//     {
//       ModeAction,
//       SchemeDirAction,
//       SidebarColorAction,
//       SidebarActiveStyleAction,
//       NavbarstyleAction,
//       ColorCustomizerAction,
//     },
//     dispatch
//   ),
// });





function App() {
  const [isAuth, setIsAuth] = useState(null);
  const[picture,setPicture]=useState("")
  // Check Login Token
  const CheckLoginToken = async () => {
    //fetch
    let response = await fetch(Variables.API_URL + "User/CheckUserLoginToken", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: Util.decrypt(localStorage.getItem("Email")),
        token: Util.decrypt(localStorage.getItem("LoginToken")),
      }),
    });
    let result = await response.json();
    //case of success
    if (response.status === 200) {
      localStorage.setItem("Email", Util.encrypt(result.Email)); // set email in localStorage
          localStorage.setItem("Permissions", Util.encrypt(result.Permissions)); // set permissions in localStorage
          localStorage.setItem("LoginToken", Util.encrypt(result.LoginToken)); // set Login Token in localStorage
          localStorage.setItem("UserId", Util.encrypt(result.UserId)); // set UserId in localStorage
          localStorage.setItem("Username", Util.encrypt(result.UserName)); // set Username in localStorage
          setPicture(result.Picture)
          
      setIsAuth(true);
    
    }
    else{
      setIsAuth(false)
    }
  };
  useEffect(() => {
    if("Email" in localStorage && "LoginToken" in localStorage){
        CheckLoginToken();
    }else{
      setIsAuth(false);
    }
}, [isAuth]);
  return (
    <div className="App">

      {isAuth===true && (
        <>
          
          <Loader />
          <Sidebar />
          <main className="main-content">
            <div className="position-relative">
              <Header setIsAuth={setIsAuth} picture={picture}/>
              <SubHeader  />
            </div>
            <div className="py-0 conatiner-fluid content-inner mt-5">
              <PrivateRoutes />
            </div>

            <Footer />
          </main>
        </>
      )}

      {isAuth===false ?(
        <PublicRoutes  setIsAuth={setIsAuth}/>
      ):null }




    </div>
  );
}

export default App;
