import React from "react";
import Index from "../views/dashboard/index";
import { Routes, Route } from "react-router-dom";
import IndexCom from "../views/index";
import BranchList from "../templates/Branches/BranchList";
import ManageBranch from "../templates/Branches/ManageBranch";
import IngredientsList from "../templates/Ingredients/IngredientsList";
import ManageIngredient from "../templates/Ingredients/ManageIngredient";
import MenusList from "../templates/Menus/MenusList";
import ManageMenu from "../templates/Menus/ManageMenu";
import ManageProduct from "../templates/Product/ManageProduct";
//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Products from "../templates/Product/Products";
import ListOrders from "../templates/Orders/ListOrders";
import ListCustomers from "../templates/Customer/ListCustomers"
import ManageCustomer from "../templates/Customer/ManageCustomer";
import Profile from "../templates/Profile/Profile";
import ListOfLanguages from "../templates/Settings/Languages/ListOfLanguages";
import ManageLanguage from "../templates/Settings/Languages/ManageLanguage";
import Settings from "../templates/Settings/Settings";
import Users from "../templates/Settings/User/Users";
import ManageUser from "../templates/Settings/User/ManageUser";
import Roles from "../templates/Settings/Roles&Permissions/Roles";
import ManageRole from "../templates/Settings/Roles&Permissions/ManageRole";
import Sizes from "../templates/Size/Sizes";
import ManageSize from "../templates/Size/ManageSize";
import Sliders from "../templates/Slider/Sliders";
import ManageSlider from "../templates/Slider/ManageSlider";
import AllSlides from "../templates/Slide/AllSlides";
import ManageSlide from "../templates/Slide/ManageSlide";
import BookingTypes from "../templates/BookingType.js/BookingTypes";
const PrivateRoutes = () => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={300}>
        <Routes>
          <Route path="/Dashboard" exact element={<Index />} />

         {/* Branch */}
         <Route path="/Branch" exact element={<BranchList/>}/>
         <Route path="/Branch/:RouteBranchId" exact element={<ManageBranch/>}/>

         {/* ingredients */}
          <Route path="/Ingredients" exact element={<IngredientsList/>} />
          <Route path="/Ingredients/:IngredientId" exact element={<ManageIngredient/>} />

          {/* Menus */}
          <Route path="/Menus" exact element={<MenusList/>} />
          <Route path="/Menus/:RouteMenuId" exact element={<ManageMenu/>} />

          {/* Sizes */}
          <Route exact path="/Sizes" element={<Sizes/>}/>
          <Route exact path="/Sizes/:SizeId" element={<ManageSize/>}/>

          {/* Products */}
          <Route path="/Product" exact element={<Products/>} />
          <Route path="/Products/:RouteProductId" exact element={<ManageProduct/>} />

          {/* Settings */}
          <Route exact path="/Settings" element={<Settings/>}/>
          <Route path="/Languages" exact element={<ListOfLanguages/>} />
          <Route path="/Languages/:LanguageID" exact element={<ManageLanguage/>} />
          <Route exact path="/Users" element={<Users/>}/>
          <Route exact path="Users/:UserId" element={<ManageUser/>}/>
          <Route exact path="/Roles" element={<Roles/>}/>
          <Route exact path="/Roles/:RoleId" element={<ManageRole/>}/>

          {/* Orders */}
          <Route path="/Orders" exact element={<ListOrders/>} />

          {/* Customer */}
          <Route path="/Customer" exact element={<ListCustomers/>} />
          <Route path="/Customer/:CustomerId" exact element={<ManageCustomer/>} />

          {/* Profile */}
          <Route exact path="/Profile" element={<Profile/>}/>


        {/* Sliders */}
        <Route exact path="/Sliders" element={<Sliders/>}/>
        <Route exact path="/Sliders/:SliderID" element={<ManageSlider/>}/>

        {/* Slides */}
        <Route exact path="/Slides" element={<AllSlides/>}/>
        <Route exact path="/Slides/:SlideID" element={<ManageSlide/>}/>

        {/* Booking Tyes */}
        <Route exact path="/BookingTypes/:BookingTypeId" element={<BookingTypes/>}/>
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default PrivateRoutes;
