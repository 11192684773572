import React, { useEffect, useState } from "react";
import { Variables } from "../../variables/Variables";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ManageSlider = () => {
  const [name, setName] = useState("");
  let { SliderID } = useParams();
  const navigate = useNavigate();
  function IsValid() {
    if (name === "") {
      toast.error("Slide Name Required");
      document.getElementById("name").focus();
      return false;
    }
    return true;
  }
  function handleSubmit() {
    if (IsValid()) {
      if (SliderID == 0) {
        const URL = Variables.API_URL + "Slider/AddSlider";
        fetch(URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            SliderName: name,
            IsPublished: false,
          }),
        }).then((response) => {
          if (response.ok) {
            toast.success("Added Successfully");
            navigate("/Sliders");
          } else {
            toast.error(response);
          }
        });
      } else {
        const URL = Variables.API_URL + "Slider/UpdateSlider/" + SliderID;
        fetch(URL, {
          method: "Put",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            SliderName: name,
          }),
        }).then((response) => {
          if (response.ok) {
            toast.success("Updated Successfully");
            navigate("/Sliders");
          } else {
            toast.error(response);
          }
        });
      }
    }
  }

  async function GetSlider() {
    const URL = Variables.API_URL + "Slider/GetSlider/" + SliderID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setName(listItems.SliderName);
  }
  useEffect(() => {
    if (SliderID != 0) {
      GetSlider();
    }
  }, []);

  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            {/* <form> */}
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-lg-8">
                    <h3>{SliderID == "0" ? "Add" : "Update"} Slider</h3>
                  </div>
                  <div className="col-lg-4 text-right">
                    <NavLink to="/Slides" className="btn btn-secondary">
                      Cancel
                    </NavLink>
                    <button
                      type="submit"
                      className="btn buttonMeatcity m-1 "
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="row">
                          <label>
                            Slider Name <span className="required">*</span>
                          </label>
                          <input
                            className="form-control"
                            id="name "
                            onChange={(e) => setName(e.target.value)}
                            value={name !== "" ? name : ""}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSlider;
