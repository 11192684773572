
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Variables } from "../../../variables/Variables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ManageLanguage = () => {
    let { LanguageID } = useParams();
    let navigate = useNavigate();
    const[title,setTile]=useState("")
    const[suffix,setSuffix]=useState("")

     function IsValid() {
        if (title ==="") {
            toast.error("Language Title Required");
            // document.getElementById("title").focus();
            return false;
        }else{
      if (suffix ==="") {
            toast.error("Suffix Required");
        //    document.getElementById("suffix").focus()

            return false;
        }
        else{
            return true
        }
        }
       
        
    }

function HandleSubmit(e) {

    //debugger;
    e.preventDefault();
    if (IsValid() === true) {
        if(LanguageID == 0){
        const URL = Variables.API_URL + "Language/AddLanguage";
        fetch(URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              title:title,
              suffix:suffix

            })
        }).then(response => {
            if (response.ok) {
                toast.success("Added Successfully")
                navigate('/Languages');
                
            }
            else {
                toast.error(response);
            }
        })
    }
    else{

        const URL = Variables.API_URL + "Language/UpdateLanguage/"+LanguageID;
        fetch(URL, {
            method: 'Put',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                title:title,
                suffix:suffix

            })
        }).then(response => {
            if (response.ok) {
                navigate('/Languages');
                toast.success("Updated Successfully")
            }
            else {
                toast.error(response);
            }
        })

    }
}
}
async function GetLanguage(id) {
    const URL = Variables.API_URL + "Language/GetLanguage/" + id;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
   setSuffix(listItems.Suffix)
   setTile(listItems.Title)
}


useEffect(() => {
    if (LanguageID != 0) {
        GetLanguage(LanguageID);
    }
}, [])

  return (
  
  <div className="container-fluid mt-3">
  <div className="row">
      <div className="col-12">
          {/* <form> */}
          <div className="card">
              <div className="card-header">
                  <div className="row">
                      <div className="col-lg-8">
                          <h3>{LanguageID == '0' ? 'Add' : 'Update'} Language</h3>
                      </div>
                      <div className="col-lg-4 text-right">
                          <NavLink to='/Languages' className='btn btn-secondary'>Cancel</NavLink>
                          <button type="submit" className="btn buttonMeatcity ml-5" onClick={HandleSubmit}>Save</button>
                      </div>

                  </div>
              </div>
              <div className="card-body">
                  <div className="row">
                      <div className="col-lg-9">
                          <div className="row">
                              <div className="col-lg-12">
                                  <label>Title <span className="required">*</span></label>
                                  <input className="form-control" id="title" onChange={(e)=>setTile(e.target.value)} value={title}/>
                              </div>
                              <div className="col-lg-12">
                                  <label>Suffix<span className="required">*</span></label>
                                  <input className="form-control" id="suffix" onChange={(e)=>setSuffix(e.target.value)} value={suffix}/>
                              </div>
                            
                          </div>
                      </div>
                  
                  </div>
              </div>
          </div>
      </div>
      </div>
      <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick />
      </div>
  )
};

export default ManageLanguage;
