import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Row, Col, Card, Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { Variables } from "../../../variables/Variables";
import { BiEdit, BiShowAlt, BiHide } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";
import { ToastContainer, toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import CustomSnipper from "../../../components/customSnipper";
import Util from "../../../Classes/Util";
const Users = () => {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  //users columns
  const columns = [
    {
      name: "#",
      selector: (row) => row.UserId,
    },
    {
      name: "Username",
      selector: (row) => row.Username,
      sortable: true,
      reorder: true,
    },
    {
      name: "Email",
      selector: (row) => row.Email,
      sortable: true,
      reorder: true,
    },
    {
      name: "Role",
      selector: (row) => row.RoleName,
      sortable: true,
      reorder: true,
    },
    {
      name: "Locked",
      selector: (row) => (
        <div>{row.IsLocked ? <FcCheckmark /> : <AiOutlineClose />}</div>
      ),
      sortable: true,
      reorder: true,
    },
    {
      name: "Options",
      selector: (row) => (
        <div>
          {permissions.UpdateUser && (
            <NavLink to={"/Users/" + row.UserId} className="btn btn-icon ">
              <BiEdit data-tip={"Manage " + row.Username} />
            </NavLink>
          )}
          {permissions.ToggleLockUser && (
            <button
              className="btn btn-icon"
              onClick={() => ToggleLock(row.UserId)}
            >
              {row.IsLocked ? (
                <BiShowAlt data-tip="Unlock" />
              ) : (
                <BiHide data-tip="Lock" />
              )}{" "}
            </button>
          )}
          <ReactTooltip place="bottom" className="tooltip" effect="solid" />{" "}
        </div>
      ),
    },
  ];
  //Pagination
  const paginationComponentOptions = {
    rowsPerPageText: "View Records",
    rangeSeparatorText: "out",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };
  //Lock/ Unlock
  const ToggleLock = async (UserId) => {
    let response = await fetch(
      Variables.API_URL + "User/ToggleLock/" + UserId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      GetListOfUsers();
      setSearch("");
    } else {
      toast.error(result);
    }
  };

  //fetch users
  async function GetListOfUsers() {
    setLoader(true);
    const URL = Variables.API_URL + "User/GetListOfUsers";
    const response = await fetch(URL);
    if (!response.ok) {
      throw Error("Did Not Receive expected data");
    } else {
      const listItems = await response.json();
      setUsers(listItems);
      setFilteredUsers(listItems);
      setLoader(false);
    }
  }

  //handle Search by  Name
  const handleSearch = (e) => {
    setSearch(e.target.value);
    let value = e.target.value.toLowerCase();
    let result = [];
    if (value === "") {
      result = users;
    } else {
      result = users.filter((child) =>
        child.Username.toLowerCase().includes(value)
      );
    }

    setFilteredUsers(result);
  };

  //#region Use Effect
  useEffect(() => {
    GetListOfUsers();
  }, []);
  //#endregion Use Effect
  return (
    <>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Users List</h4>
              </div>
              {permissions.AddUser && (
                <Button
                  variant="btn buttonMeatcity"
                  onClick={() => navigate("/Users/0")}
                >
                  Add New User
                </Button>
              )}
            </Card.Header>

            <Card.Body>
              <Row style={{ marginBottom: "20px" }}>
                <Col sm="6" md="4" lg="4" className="text-right">
                  <input
                    type="text"
                    onChange={(e) => handleSearch(e)}
                    className="form-control"
                    autoFocus={true}
                    placeholder="Search By Name"
                    value={search}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  {loader ? (
                    <CustomSnipper />
                  ) : (
                    <DataTable
                      columns={columns}
                      data={filteredUsers}
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                    />
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default Users;
