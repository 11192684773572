import React, { useState } from "react";
import { Row, Col, Image, Form, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card";
import validator from "validator";
import { Variables } from "../../variables/Variations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// img
import auth2 from "../../assets/images/auth/02.png";
const Recoverpw = () => {
  
  const [email, setEmail] = useState("");
  const [emailFormatError, setEmailFormatError] = useState(false);
  const [emailRequiredError, setEmailRequiredError] = useState(false);
  const [loader,setLoader] = useState(false);
  const navigate = useNavigate();

  //handle email change
  const handleEmail = (e) => {
    const { value } = e.target;
    if (validator.isEmail(value)) {
      var element = document.getElementById("Email");
      element.classList.add("is-invalid");
    }
    if (value === "") {
      var element = document.getElementById("Email");
      element.classList.add("is-invalid");
    } else {
      var element = document.getElementById("Email");
      element.classList.remove("is-invalid");
    }
    setEmail(value);
  };

  // check input validity
  function IsValid() {
    var hasError = false;
    if (email == null || email == "") {
      var element = document.getElementById("Email");
      element.classList.add("is-invalid");
      hasError = true;
    } else if (!validator.isEmail(email)) {
      var element = document.getElementById("Email");
      element.classList.add("is-invalid");
      hasError = true;
    }
    if (hasError === true) {
      return false;
    } else {
      return true;
    }
  }

  // submit button
  const Submit = async () => {
    setLoader(true);
    var isValid = IsValid();
    if (isValid === true) {
      let response = await fetch(
        Variables.API_URL + "User/ForgetPassword",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Email: email
          }),
        }
      );
      let result = await response.json();
      if (response.status === 200) {
        toast.success(result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          setLoader(false);
          navigate("/");
        }, 3000);
      } else {
        toast.error(result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
        setLoader(false);
      }
    }
    setLoader(false);
  };



  return (
  <>
      <section className="login-content">
        <Row className="m-0 align-items-center bg-white vh-100">
          <Col
            md="6"
            className="d-md-block d-none  p-0 mt-n1 vh-100 overflow-hidden"
          >
          <Image
             src="../../../meatcityLogo.png"
              className="Image-fluid gradient-main animated-scaleX"
              alt="images"
            />
          </Col>
          <Col md="6" className="p-0">
            <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
              <Card.Body>
                <Link
                  to="/"
                  className="navbar-brand d-flex align-items-center mb-3"
                >
                   <img width="30" viewBox="0 0 30 30" fill="none" src="../../../meatcityLogo.png"/>
                  <h4 className="logo-title ms-3">Bahia Khanoum</h4>
                </Link>
                <h2 className="mb-2">Reset Password</h2>
                <p>
                  Enter your email address and we'll send you an email with
                  instructions to reset your password.
                </p>
                <Form>
                  <Row>
                    <Col lg="12" className="col-lg-12">
                      <Form.Group className="floating-label">
                        <Form.Label htmlFor="email" className="form-label">
                          Email
                        </Form.Label>
                        <Form.Control
                          id="Email"
                          name="Email"
                          aria-describedby="email"
                          placeholder="Email Address"
                          type="text"
                          className="form-control"
                          value={email}
                          onChange={handleEmail}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                    onClick={() => Submit()}
                    className="mt-3"
                    type="button"
                    variant="btn buttonMeatcity"
                  >
                    {loader === true ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="visually-hidden">Loading...</span>
                      </>
                    ) : (
                      "Reset"
                    )}
                  </Button>{" "}
                  <Button
                    onClick={() => navigate("/")}
                    className="mt-3"
                    type="button"
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                </Form>
              </Card.Body>
            </Card>
         
          </Col>
        </Row>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </section>
    </>
  );
};

export default Recoverpw;
