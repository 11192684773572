import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import CustomSnipper from "../../components/customSnipper";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Variables } from "../../variables/Variables";
import { BiEdit, BiShowAlt, BiHide } from "react-icons/bi";
import ReactTooltip from "react-tooltip";
import DataTable from "react-data-table-component";
import Util from "../../Classes/Util";
function IngredientsList() {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [loader, setLoader] = useState(false);
  const [ingredients, setIngredients] = useState([]);
  const [filteredIngredients, setFilteredIngredients] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  //handle Search by Ingredient Name
  const handleSearch = (e) => {
    setSearch(e.target.value);
    let value = e.target.value.toLowerCase();
    let result = [];
    if (value === "") {
      result = ingredients;
    } else {
      result = ingredients.filter((child) =>
        child.Name.toLowerCase().includes(value)
      );
    }

    setFilteredIngredients(result);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "View Records",
    rangeSeparatorText: "out",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  // Get Ingredients
  const GetIngredients = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL + "Ingredient/GetIngredientsForTable",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setIngredients(result);
      setFilteredIngredients(result);
      setLoader(false);
    }
  };

  //Ingredients Columns
  const ingredientsColumns = [
    {
      name: "#",
      selector: (row) => row.IngredientId,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
    },
    {
      name: "Publish",
      selector: (row) => (
        <span
          className={
            row.IsPublished === false ? "badge bg-danger" : "badge bg-success"
          }
        >
          {row.IsPublished === true ? "Published" : "Unpublished"}
        </span>
      ),
      sortable: true,
    },

    {
      name: "Actions",
      selector: (row) => (
        <div>
          {permissions.UpdateIngredient && (
            <Link
              className="btn btn-sm btn-icon "
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title="Edit"
              to={"/Ingredients/" + row.IngredientId}
            >
              <span className="btn-inner">
                <BiEdit data-tip={"Manage " + row.Name} />
              </span>
              <ReactTooltip place="bottom" className="tooltip" effect="solid" />
            </Link>
          )}
          {permissions.TogglePublishIngredient && (
            <button
              className="btn  btn-icon ml-5"
              onClick={() => TooglePublish(row.IngredientId)}
            >
              {row.IsPublished ? (
                <BiHide data-tip="Unpublish" />
              ) : (
                <BiShowAlt data-tip="Publish" />
              )}
            </button>
          )}
        </div>
      ),
    },
  ];
  const TooglePublish = async (ingredientId) => {
    let response = await fetch(
      Variables.API_URL + "Ingredient/TogglePublish/" + ingredientId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    if (response.ok === false) {
      toast.error(result);
    } else {
      toast.success(result);
      setSearch("");
      GetIngredients();
    }
  };

  useEffect(() => {
    GetIngredients();
  }, []);

  return (
    <>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Ingredients List</h4>
              </div>
              {permissions.AddIngredient && (
                <Button
                  variant="btn buttonMeatcity"
                  onClick={() => navigate("/Ingredients/0")}
                >
                  Add New Ingredient
                </Button>
              )}
            </Card.Header>

            <Card.Body>
              <Row style={{ marginBottom: "20px" }}>
                <Col sm="6" md="4" lg="4" className="text-right">
                  <input
                    type="text"
                    onChange={(e) => handleSearch(e)}
                    value={search}
                    className="form-control"
                    autoFocus={true}
                    placeholder="Search By Name"
                  />
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  {loader ? (
                    <CustomSnipper />
                  ) : (
                    <DataTable
                      columns={ingredientsColumns}
                      data={filteredIngredients}
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                    />
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default IngredientsList;
