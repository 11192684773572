import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Variables } from "../../variables/Variables";
import CustomSnipper from "../../components/customSnipper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import { AiOutlineClose } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";
import { BiEdit, BiShowAlt, BiHide } from "react-icons/bi";
import Util from "../../Classes/Util";
import ReactTooltip from "react-tooltip";

function BookingTypes() {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [loader, setLoader] = useState(false);
  const [bookingTypes, setBookingTypes] = useState([]);
  const [filteredBookingTypes, setfilteredBookingTypes] = useState([]);
  const [show, setshow] = useState(0);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const paginationComponentOptions = {
    rowsPerPageText: "View Records",
    rangeSeparatorText: "out",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  // Get Booking Types List
  const GetBookingTypesList = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL + "BookingType/GetAllBookingTypes",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setBookingTypes(result);
      setfilteredBookingTypes(result);
    }
    setLoader(false);
  };
  const handleSearch = (e) => {
    let value = e.target.value.toLowerCase();
    setSearch(e.target.value);
    let result = [];
    if (value === "") {
      result = bookingTypes;
    } else {
      result = bookingTypes.filter((child) =>
        child.Type.toLowerCase().includes(value)
      );
    }

    setfilteredBookingTypes(result);
  };
  // Active Deactivate BookingType
  const TogglePublishType = async (Id) => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL + "BookingType/TogglePublishType/" + Id,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
      GetBookingTypesList();
      setSearch("");
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }

    setshow(0);
    setLoader(false);
  };

  const typeColumns = [
    {
      name: "#",
      selector: (row) => row.BookingTypeId,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.Type,
      sortable: true,
    },
    {
        name: "Image",
        selector: (row) => (
              <img
                src={Variables.ImagePath + row.Image}
                style={{ width: "100px", padding: "5px" }}
              />
           
          
        ),
       
      },
    {
      name: "Active",
      selector: (row) => (
        <div>{row.IsPublished ? <FcCheckmark /> : <AiOutlineClose />}</div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
       
            <Link
              className="btn btn-sm btn-icon "
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title="Edit"
              to={"/BookingTypes/" + row.BookingTypeId}
            >
              <span className="btn-inner">
                <BiEdit />
              </span>
            </Link>
       

        
            <button
              className="btn  btn-icon ml-5"
              onClick={() => setshow(row.BookingTypeId)}
            >
              {row.IsPublished ? (
                <BiHide data-tip="Unpublish" />
              ) : (
                <BiShowAlt data-tip="Publish" />
              )}
            </button>
        
          <ReactTooltip place="bottom" className="tooltip" effect="solid" />
          <Modal
            show={row.BookingTypeId === show}
            onHide={() => setshow(0)}
          >
            <Modal.Header closeButton>
              <Modal.Title as="h5">
                {row.IsActive ? "Unpublish BookingType" : "Publish BookingType"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to{" "}
              {row.IsPublished ? "Unpublish : " : "Publish : "}
              BookingType {row.Type}?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setshow(0)}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => TogglePublishType(row.BookingTypeId)}
              >
                Save changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];
  useEffect(() => {
    GetBookingTypesList();
  }, []);

  return (
    <>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Booking Types List</h4>
              </div>
            
                <Button
                  variant="btn buttonMeatcity"
                  onClick={() => navigate("/BookingTypes/0")}
                >
                  Add New Booking Type
                </Button>
           
            </Card.Header>

            <Card.Body>
              <Row style={{ marginBottom: "20px" }}>
                <Col sm="6" md="4" lg="4" className="text-right">
                  <input
                    type="text"
                    onChange={(e) => handleSearch(e)}
                    className="form-control"
                    autoFocus={true}
                    placeholder="Search By Name"
                    value={search}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  {loader ? (
                    <CustomSnipper />
                  ) : (
                    <DataTable
                      columns={typeColumns}
                      data={filteredBookingTypes}
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                    />
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default BookingTypes;
