import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { Variables } from "../../variables/Variables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomSnipper from "../../components/customSnipper";
import Util from "../../Classes/Util";
import { FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import Dropzone from "react-dropzone";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import {
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Tab,
  Tabs,
  Nav,
  FormCheck,
  Card,
  InputGroup,
  FormControl,
} from "react-bootstrap";
//import BranchDelivery from "./Components/BranchDelivery";
//import BranchLocation from "./Components/BranchLocation";
import "antd/dist/antd.css";
import { TimePicker } from "antd";
import moment from "moment";

function ManageBranch() {
  const RouteBranchId = useParams();
  const [branchId, setBranchId] = useState(
    Number(RouteBranchId.RouteBranchId) || 0
  );

  const navigate = useNavigate();
  const [detailsLoader, setDetailsLoader] = useState(false);

  const [branchNumbers, setBranchNumbers] = useState([
    {
      NumberId: 0,
      PhoneNumber: "",
    },
  ]);

  const [fixedBranchDetails, setFixedBranchDetails] = useState({
    CityId: 0,
    DeliveryCharge: 0,
    DeliveryEndTime: "00:00:00",
    DeliveryStartTime: "00:00:00",
    IsActive: true,
    Lattitude: 0,
    Longtitude: 0,
    MinimumOrderPrice: 0,
    OpeningEndTime: "00:00:00",
    OpeningStartTime: "00:00:00",
    Stock: 0,
  });
  const [unfixedBranchDetails, setUnfixedBranchDetails] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState([
    {
      BranchNumberId: 0,
      PhoneNumber: "",
    },
  ]);
  const [cities, setCities] = useState([]);
  const [startTime, setStartTime] = useState("");

  function SetStart(value){
    setStartTime(value);
  }

  // Get BranchDetails
  const GetBranchDetails = async () => {
    let response = await fetch(
      Variables.API_URL + "Branch/GetBranchDetails/" + branchId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setFixedBranchDetails({
        CityId: result.BranchFixedItems.CityId,
        DeliveryCharge: result.BranchFixedItems.DeliveryCharge,
        DeliveryEndTime: result.BranchFixedItems.DeliveryEndTime,
        DeliveryStartTime: result.BranchFixedItems.DeliveryStartTime,
        IsActive: result.BranchFixedItems.IsActive,
        Lattitude: result.BranchFixedItems.Lattitude,
        Longtitude: result.BranchFixedItems.Longtitude,
        MinimumOrderPrice: result.BranchFixedItems.MinimumOrderPrice,
        OpeningEndTime: result.BranchFixedItems.OpeningEndTime,
        OpeningStartTime: result.BranchFixedItems.OpeningStartTime,
        Stock: result.BranchFixedItems.Stock,
      });
      //setFixedBranchDetails(result.BranchFixedItems);
      //setFixedBranchDetails(result.BranchFixedItems)
      SetStart(result.BranchFixedItems.fixedBranchDetails)

      setBranchNumbers(result.BranchFixedItems.PhoneNumbers);
      setUnfixedBranchDetails(result.BranchUnFixedItems);
      setSelectedProducts(result.BranchFixedItems.Products);
      setSelectedUsers(result.BranchFixedItems.Users);
    }
  };

  // Get Users
  const GetUsers = async () => {
    let response = await fetch(Variables.API_URL + "User/GetUsers", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setUsers(result);
    }
  };

  // Get Products
  const GetProducts = async () => {
    let response = await fetch(Variables.API_URL + "Product/GetProducts", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setProducts(result);
    }
  };

  // Get City
  const GetCities = async () => {
    let response = await fetch(Variables.API_URL + "City/GetCitiesForDdl", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setCities(result);
    }
  };

  // Handle name change
  const HandleNameChange = (e, i) => {
    const { name, value } = e.target;

    if (value === "") {
      var ele = document.getElementById("name" + i);
      ele.classList.add("is-invalid");
    }
    var detailsCopy = [...unfixedBranchDetails];
    var detailsCopyToUpdate = detailsCopy[i];
    delete detailsCopy[i];
    detailsCopyToUpdate["Name"] = e.target.value;
    detailsCopy[i] = detailsCopyToUpdate;
    setUnfixedBranchDetails(detailsCopy);
  };

  // Handle Address change
  const HandleAddressChange = (e, i) => {
    const { name, value } = e.target;

    if (value === "") {
      var ele = document.getElementById("address" + i);
      ele.classList.add("is-invalid");
    }
    var detailsCopy = [...unfixedBranchDetails];
    var detailsCopyToUpdate = detailsCopy[i];
    delete detailsCopy[i];
    detailsCopyToUpdate["Address"] = e.target.value;
    detailsCopy[i] = detailsCopyToUpdate;
    setUnfixedBranchDetails(detailsCopy);
  };

  // handle City Change
  const handleCityChange = (e) => {
    const { value } = e.target;
    var element;
    if (value === 0 || value === "0") {
      element = document.getElementById("CityId");
      element.classList.add("is-invalid");
    } else {
      element = document.getElementById("CityId");
      element.classList.remove("is-invalid");
    }
    setFixedBranchDetails((prevState) => ({
      ...prevState,
      ["CityId"]: value,
    }));
  };

  // handle Branch Details Inputs
  const handleInputs = (e) => {
    const { name, value } = e.target;

    var element;
    if (value === "") {
      element = document.getElementById(name);
      element.classList.add("is-invalid");
    } else {
      element = document.getElementById(name);
      element.classList.remove("is-invalid");
    }
    setFixedBranchDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //onOpen Time Change
  function onOpenTimeChange(time, timeString) {
    setFixedBranchDetails((prevState) => ({
      ...prevState,
      ["OpeningStartTime"]: timeString,
    }));
  }

  //on Close Time Change
  function onCloseTimeChange(time, timeString) {
    setFixedBranchDetails((prevState) => ({
      ...prevState,
      ["OpeningEndTime"]: timeString,
    }));
  }

  //on Delivery Start Time Change
  function onDeliveryStartTimeChange(time, timeString) {
    setFixedBranchDetails((prevState) => ({
      ...prevState,
      ["DeliveryStartTime"]: timeString,
    }));
  }

  // on Delivery End Time Change
  function onDeliveryEndTimeChange(time, timeString) {
    setFixedBranchDetails((prevState) => ({
      ...prevState,
      ["DeliveryEndTime"]: timeString,
    }));
  }

  // select users change
  const handleAssigneUsers = (e) => {
    setSelectedUsers(e);
  };

  // select products change
  const handleSelectProducts = (e) => {
    setSelectedProducts(e);
  };

   // handle Branch Numbers Inputs
   const handleBranchNumbersInputs = (e, i) => {
    const { name, value } = e.target;

    var element;
    if (value === "") {
      element = document.getElementById(name + i);
      element.classList.add("is-invalid");
    } else {
      element = document.getElementById(name + i);
      element.classList.remove("is-invalid");
    }

    let itterationToUpdate = branchNumbers[i];
    const array = [...branchNumbers];
    delete array[i];

    let numberToUpdate = itterationToUpdate.PhoneNumber;
    numberToUpdate = value;

    itterationToUpdate.PhoneNumber = numberToUpdate;

    array[i] = itterationToUpdate;

    setPhoneNumber(array);
  };

    // Delete PhoneNumber
    const DeletePhoneNumber = (index) => {
      const array = [...branchNumbers];
      array.splice(index, 1);
      if (array.length === 0) {
        array.push({
          BranchNumberId: 0,
          PhoneNumber: "",
        });
      }
      setBranchNumbers(array);
    };

    //Add New Phone
  const AddNewPhone = () => {
    const array = [...branchNumbers];
    const arrayLenght = array.length;
    if (array[arrayLenght - 1].PhoneNumber === "") {
      var element;
      var n = arrayLenght - 1;
      element = document.getElementById("PhoneNumber" + n);
      element.classList.add("is-invalid");
    } else {
      array.push({
        NumberId: 0,
        PhoneNumber: "",
      });
      setBranchNumbers(array);
    }
  };

  const AddNewBranch = async () => {
    let response = await fetch(
      Variables.API_URL + "Branch/AddBranch",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          BranchId: 0,
          BranchFixedItems: fixedBranchDetails,
          BranchUnFixedItems: unfixedBranchDetails,
          Users: selectedUsers,
          Products: selectedProducts,
          PhoneNumbers: branchNumbers
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const UpdateBranch = async () => {
    setDetailsLoader(true);
    let response = await fetch(
      Variables.API_URL + "Branch/UpdateBranch",
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          BranchId: branchId,
          BranchFixedItems: fixedBranchDetails,
          BranchUnFixedItems: unfixedBranchDetails,
          Users: selectedUsers,
          Products: selectedProducts,
          PhoneNumbers: branchNumbers
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setDetailsLoader(false);
  };

  const HandleSubmit = () =>{
    if(branchId === 0){
      AddNewBranch()
    }
    else{
      UpdateBranch()
    }
  }

  
  useEffect(() => {
    GetCities();
    GetUsers();
    GetProducts();
    GetBranchDetails();
  }, [branchId]);

  return (
    <div>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Manage Branch</h4>
              </div>
              <Button variant="secondary" onClick={() => navigate("/Branch")}>
                Cancel
              </Button>
            </Card.Header>
            {detailsLoader === true ? (
              <CustomSnipper />
            ) : (
              <Card.Body>
                <div className="new-user-info">
                  <div className="bd-example">
                    <Tab.Container defaultActiveKey="Details">
                      <Nav>
                        <Nav
                          variant="tabs"
                          className="mb-3"
                          id="nav-tab"
                          role="tablist"
                        >
                          <Nav.Link
                            eventKey="Details"
                            variant=" d-flex align-items-center"
                            id="nav-Details-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-Details"
                            type="button"
                            role="tab"
                            aria-controls="nav-Details"
                            aria-selected="true"
                          >
                            Branch Details
                          </Nav.Link>

                          {branchId !== 0 && (
                            <>
                              <Nav.Link
                                eventKey="Delivery"
                                variant=""
                                id="nav-Delivery-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-Delivery"
                                type="button"
                                role="tab"
                                aria-controls="nav-Delivery"
                                aria-selected="false"
                              >
                                Delivery Regions
                              </Nav.Link>

                              <Nav.Link
                                eventKey="Media"
                                variant=""
                                id="nav-Media-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-Media"
                                type="button"
                                role="tab"
                                aria-controls="nav-Media"
                                aria-selected="false"
                              >
                                Media
                              </Nav.Link>
                            </>
                          )}
                        </Nav>
                      </Nav>

                      <Tab.Content>
                        <Tab.Pane
                          className=" fade show"
                          eventKey="Details"
                          id="nav-Details"
                          role="tabpanel"
                          aria-labelledby="nav-Details-tab"
                        >
                          {detailsLoader === true ? (
                            <CustomSnipper />
                          ) : (
                            <>
                              <br />
                              <Row>
                                {unfixedBranchDetails.map((data, i) => (
                                  <Col lg="6" md="6" xs="12" key={i}>
                                    <Card
                                      style={{ backgroundColor: "aliceblue" }}
                                    >
                                      <Card.Header
                                        style={{ backgroundColor: "aliceblue" }}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex align-items-center">
                                            <h4 className="px-3">
                                              Language {data.LanguageName}
                                            </h4>
                                          </div>
                                          <div className="d-flex align-items-center justify-content-end"></div>
                                        </div>
                                      </Card.Header>
                                      <Card.Body>
                                        <br />
                                        <br />
                                        <Form className="d-grid gap-card">
                                          <Form.Group className="mb-2">
                                            <Form.Label htmlFor="fname">
                                              Branch Name :
                                            </Form.Label>
                                            <InputGroup>
                                              <FormControl
                                                type="text"
                                                placeholder={
                                                  "Branch Name in " +
                                                  data.LanguageName
                                                }
                                                id={"name" + i}
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon3"
                                                value={data.Name}
                                                onChange={(e) =>
                                                  HandleNameChange(e, i)
                                                }
                                              />
                                              <InputGroup.Text
                                                as="span"
                                                className="input-group-text"
                                                id="name"
                                              >
                                                {data.LanguageSuffix}
                                              </InputGroup.Text>
                                            </InputGroup>
                                          </Form.Group>

                                          <Form.Group className="mb-2">
                                            <Form.Label htmlFor="fname">
                                              Branch Address :
                                            </Form.Label>
                                            <InputGroup>
                                              <FormControl
                                                type="text"
                                                placeholder={
                                                  "Address Decription in " +
                                                  data.LanguageName
                                                }
                                                id={"address" + i}
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon3"
                                                value={data.Address}
                                                onChange={(e) =>
                                                  HandleAddressChange(e, i)
                                                }
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                        </Form>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                ))}
                              </Row>
                              <br />
                              <hr />
                              <br />

                              <Row>
                                <Form.Group className="col-md-6 form-group">
                                  <Form.Label htmlFor="fname">City:</Form.Label>
                                  <select
                                    className="form-control"
                                    name="CityId"
                                    id="CityId"
                                    value={fixedBranchDetails.CityId}
                                    onChange={handleCityChange}
                                  >
                                    <option key={0} value={0}>
                                      -- Select City --
                                    </option>
                                    {cities.map((x) => {
                                      return (
                                        <option key={x.CityId} value={x.CityId}>
                                          {x.CityName}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </Form.Group>

                                <Form.Group className="col-md-6 form-group">
                                  <Form.Label htmlFor="fname">
                                  Delivery Charge :
                                  </Form.Label>
                                  <InputGroup>
                                    <Form.Control
                                      id="DeliveryCharge"
                                      name="DeliveryCharge"
                                      placeholder="Delivery Charge"
                                      type="number"
                                      value={fixedBranchDetails.DeliveryCharge}
                                      onChange={handleInputs}
                                    />
                                    <InputGroup.Text
                                      as="span"
                                      className="input-group-text"
                                      id="name"
                                    >
                                      AED
                                    </InputGroup.Text>
                                  </InputGroup>
                                </Form.Group>

                                <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                                  <Form.Label htmlFor="fname">
                                    Open Time :
                                  </Form.Label>
                                  <TimePicker
                                    id="OpeningStartTime"
                                    use12Hours
                                    className="form-control"
                                    value={moment(
                                      fixedBranchDetails.OpeningStartTime,
                                      "HH:mm"
                                    )}
                                    defaultValue={moment(
                                      fixedBranchDetails.OpeningStartTime,
                                      "HH:mm"
                                    )}
                                    format="HH:mm"
                                    onChange={onOpenTimeChange}
                                  />
                                </Form.Group>

                                <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                                  <Form.Label htmlFor="fname">
                                    Close Time :
                                  </Form.Label>
                                  <TimePicker
                                    id="OpeningEndTime"
                                    use12Hours
                                    className="form-control"
                                    value={moment(
                                      fixedBranchDetails.OpeningEndTime,
                                      "HH:mm"
                                    )}
                                    defaultValue={moment(
                                      fixedBranchDetails.OpeningEndTime,
                                      "HH:mm"
                                    )}
                                    format="HH:mm"
                                    onChange={onCloseTimeChange}
                                  />
                                </Form.Group>

                                <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                                  <Form.Label htmlFor="fname">
                                    Delivery Start Time :
                                  </Form.Label>
                                  <TimePicker
                                    id="DeliveryStartTime"
                                    use12Hours
                                    className="form-control"
                                    value={moment(
                                      fixedBranchDetails.DeliveryStartTime,
                                      "HH:mm"
                                    )}
                                    defaultValue={moment(
                                      fixedBranchDetails.DeliveryStartTime,
                                      "HH:mm"
                                    )}
                                    format="HH:mm"
                                    onChange={onDeliveryStartTimeChange}
                                  />
                                </Form.Group>

                                <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                                  <Form.Label htmlFor="fname">
                                    Delivery End Time
                                  </Form.Label>
                                  <TimePicker
                                    id="DeliveryEndTime"
                                    use12Hours
                                    className="form-control"
                                    value={moment(
                                      fixedBranchDetails.DeliveryEndTime,
                                      "HH:mm"
                                    )}
                                    defaultValue={moment(
                                      fixedBranchDetails.DeliveryEndTime,
                                      "HH:mm"
                                    )}
                                    format="HH:mm"
                                    onChange={onDeliveryEndTimeChange}
                                  />
                                </Form.Group>

                                <Form.Group className="col-md-6 form-group">
                                  <Form.Label htmlFor="DeliveryCharge">
                                    Minimum Order Price :
                                  </Form.Label>
                                  <Form.Control
                                    id="MinimumOrderPrice"
                                    name="MinimumOrderPrice"
                                    placeholder="Minimum Order Price"
                                    type="number"
                                    value={fixedBranchDetails.MinimumOrderPrice}
                                    onChange={handleInputs}
                                  />
                                </Form.Group>

                                <Form.Group className="col-md-6 form-group">
                                  <Form.Label htmlFor="DeliveryCharge">
                                    Stock :
                                  </Form.Label>
                                  <Form.Control
                                    id="Stock"
                                    name="Stock"
                                    placeholder="Stock"
                                    type="number"
                                    value={fixedBranchDetails.Stock}
                                    onChange={handleInputs}
                                  />
                                </Form.Group>
                              </Row>

                              <br/>

                              <b>
                              Phone Numbers :{" "}
                              <button
                                className="btn-icon1"
                                onClick={AddNewPhone}
                              >
                                <FaPlusCircle />
                              </button>
                            </b>
                            <hr style={{ width: "200px" }} className="mt-2" />
                            <br></br>
                              <Row>
                                  {branchNumbers.map((data, i) => (
                                    <Col key={i} md="6">
                                      <Row>
                                        <Form.Group className="col-sm-10 form-group">
                                          <Form.Control
                                            key={i}
                                            id={"PhoneNumber" + i}
                                            name="PhoneNumber"
                                            placeholder="Phone Number"
                                            type="text"
                                            className="mt-2"
                                            value={data.PhoneNumber}
                                            onChange={(e) =>
                                              handleBranchNumbersInputs(e, i)
                                            }
                                          />
                                        </Form.Group>
                                        <Form.Group className="col-sm-2 form-group mt-2">
                                          <Button
                                            className="btn-icon1"
                                            variant="outline-danger"
                                            onClick={() => DeletePhoneNumber(i)}
                                          >
                                            <FaTrashAlt />
                                          </Button>
                                        </Form.Group>
                                      </Row>
                                    </Col>
                                  ))}
                                </Row>

                              <br />
                              <Row>
                                <br />
                                <b>Users : </b>
                                <hr
                                  style={{ width: "200px" }}
                                  className="mt-2"
                                />
                                <br></br>

                                <Form.Group className="col-md-12 form-group">
                                  <Form.Label htmlFor="fname">
                                    Select :
                                  </Form.Label>
                                  <Multiselect
                                    options={users} // Options to display in the dropdown
                                    selectedValues={selectedUsers} // Preselected value to persist in dropdown
                                    displayValue="Name" // Property name to display in the dropdown options
                                    placeholder="Assign Users"
                                    id="Users"
                                    name="Users"
                                    onSelect={handleAssigneUsers}
                                    onRemove={handleAssigneUsers}
                                  />
                                </Form.Group>
                              </Row>

                              <br />
                              <Row>
                                <br />
                                <b>Products : </b>
                                <hr
                                  style={{ width: "200px" }}
                                  className="mt-2"
                                />
                                <br></br>

                                <Form.Group className="col-md-12 form-group">
                                  <Form.Label htmlFor="fname">
                                    Select :
                                  </Form.Label>
                                  <Multiselect
                                    options={products} // Options to display in the dropdown
                                    selectedValues={selectedProducts} // Preselected value to persist in dropdown
                                    displayValue="Name" // Property name to display in the dropdown options
                                    placeholder="Select Products"
                                    id="Products"
                                    name="Products"
                                    onSelect={handleSelectProducts}
                                    onRemove={handleSelectProducts}
                                  />
                                </Form.Group>
                              </Row>

                              <div className="float-right">
                                <Button
                                  type="button"
                                  variant="btn buttonMeatcity"
                                  
                                  onClick={HandleSubmit}
                                >
                                  Submit
                                </Button>{" "}
                                <Button
                                  type="button"
                                  variant="btn btn-secondary"
                                  onClick={() => navigate("/Branch")}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </Card.Body>
            )}
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ManageBranch;
