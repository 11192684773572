import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { NavLink } from "react-router-dom"
import { Variables } from "../../variables/Variables";
import {  BiEdit, BiShowAlt, BiHide } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify';
import {AiOutlineClose,AiOutlineCheck} from 'react-icons/ai'

const Sliders = () => {
    const [sliders, setSliders] = useState();
    const columns = [
        {
            name: 'ID',
            selector: row => row.SliderId,
            sortable: true,

        },
        {
            name: 'Slider Name',
            selector: row => row.SliderName,
            sortable: true,

        },
        {
            name: 'Published',
            selector: row => row.IsPublished?<AiOutlineCheck/> :<AiOutlineClose/>,
            sortable: true,

        },
        {
            name: 'Options',
            selector: row => <div>
               <NavLink to={"/Sliders/" + row.SliderId} className="btn btn-icon" ><BiEdit /></NavLink>
       <button className="btn btn-icon" onClick={() => TooglePublishSlider(row.SliderId)}>{row.IsPublished ? <BiHide /> : <BiShowAlt />} </button>

            </div>,
        }
    ];
  
  
    async function TooglePublishSlider(id) {
        let response = await fetch(
            Variables.API_URL + "Slider/TooglePublishSlider/" + id,
            {
              method: "Put",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );
          let result = await response.json();
          if (response.ok) {
            toast.success(result);
            GetSliders();
          } else {
            toast.error(result);
          }
    }
    async function GetSliders() {
        const URL = Variables.API_URL + "Slider/GetSliders";
        const response = await fetch(URL)
        if (!response.ok) throw Error("Did Not Receive expected data");
        const listItems = await response.json();
        setSliders(listItems);
    }
       //#region Use Effect 
       useEffect(() => {
        GetSliders();
    }, [])
    //#endregion Use Effect 
    return (
        <div className="rightSide">
        <div className="container-fluid mt-3">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h3>List Sliders</h3>
                                    </div>
                                    <div className="col-lg-4 text-right">
                                        <NavLink to='/Sliders/0' className='btn buttonMeatcity'>Add Slider</NavLink>
                                    </div>
                                </div>
                        </div>
                        <div className="card-body">
                            <DataTable
                                columns={columns}
                                data={sliders}
                                pagination />
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick />
        </div>
        </div>
    )
}
export default Sliders;