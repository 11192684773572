import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Row, Col, Card, Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { Variables } from "../../../variables/Variables";
import { BiEdit, BiShowAlt, BiHide } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";
import { ToastContainer, toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import CustomSnipper from "../../../components/customSnipper";
import Util from "../../../Classes/Util";
const Roles = () => {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [roles, setRoles] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  //roles columns
  const columns = [
    {
      name: "#",
      selector: (row) => row.RoleId,
    },
    {
      name: "Role Name",
      selector: (row) => row.RoleName,
      sortable: true,
      reorder: true,
    },

    {
      name: "Active",
      selector: (row) => (
        <div>{row.IsActive ? <FcCheckmark /> : <AiOutlineClose />}</div>
      ),
      sortable: true,
      reorder: true,
    },
    {
      name: "Options",
      selector: (row) => (
        <div>
          {permissions.UpdateRole && (
            <NavLink to={"/Roles/" + row.RoleId} className="btn btn-icon ">
              <BiEdit data-tip={"Manage " + row.RoleName} />
            </NavLink>
          )}
          {permissions.ToggleRoleActivation && (
            <button
              className="btn btn-icon"
              onClick={() => ToggleActivation(row.RoleId)}
            >
              {row.IsActive ? (
                <BiShowAlt data-tip="Deactivate" />
              ) : (
                <BiHide data-tip="Activate" />
              )}{" "}
            </button>
          )}
          <ReactTooltip place="bottom" className="tooltip" effect="solid" />{" "}
        </div>
      ),
    },
  ];
  //Pagination
  const paginationComponentOptions = {
    rowsPerPageText: "View Records",
    rangeSeparatorText: "out",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };
  //Lock/ Unlock
  const ToggleActivation = async (RoleId) => {
    let response = await fetch(
      Variables.API_URL + "Role/ToggleActivation/" + RoleId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      GetRoles();
      setSearch("");
    } else {
      toast.error(result);
    }
  };

  //fetch roles
  async function GetRoles() {
    setLoader(true);
    const URL = Variables.API_URL + "Role/GetRoles";
    const response = await fetch(URL);
    if (!response.ok) {
      throw Error("Did Not Receive expected data");
    } else {
      const listItems = await response.json();
      setRoles(listItems);
      setFilteredRoles(listItems);
      setLoader(false);
    }
  }

  //handle Search by  Name
  const handleSearch = (e) => {
    setSearch(e.target.value);
    let value = e.target.value.toLowerCase();
    let result = [];
    if (value === "") {
      result = roles;
    } else {
      result = roles.filter((child) =>
        child.RoleName.toLowerCase().includes(value)
      );
    }

    setFilteredRoles(result);
  };

  //#region Use Effect
  useEffect(() => {
    GetRoles();
  }, []);
  //#endregion Use Effect
  return (
    <>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Roles List</h4>
              </div>
              {permissions.AddRole && (
                <Button
                  variant="btn buttonMeatcity"
                  onClick={() => navigate("/Roles/0")}
                >
                  Add New Role
                </Button>
              )}
            </Card.Header>

            <Card.Body>
              <Row style={{ marginBottom: "20px" }}>
                <Col sm="6" md="4" lg="4" className="text-right">
                  <input
                    type="text"
                    onChange={(e) => handleSearch(e)}
                    className="form-control"
                    autoFocus={true}
                    placeholder="Search By Name"
                    value={search}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  {loader ? (
                    <CustomSnipper />
                  ) : (
                    <DataTable
                      columns={columns}
                      data={filteredRoles}
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                    />
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default Roles;
