import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
} from "react-bootstrap";
import {  useNavigate} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Variables } from "../../variables/Variables";
import CustomSnipper from "../../components/customSnipper";
import Util from "../../Classes/Util";
import axios from "axios";
const Profile = () => {
    const [loader, setLoader] = useState(false);
    const navigate=useNavigate();
    const UserId=Util.decrypt(localStorage.getItem("UserId"));
    const[user,setUser]=useState({
        Username:"",
        FirstName:"",
        LastName:"",
        PhoneNumber:"",
        ProfilePicture:"",
        ProfilePicturePath:""
    });
    function IsValid(){
        if(user.Username===""){
            toast.error("Username Is required");
            return false;
        }
        else if(user.FirstName===""){
            toast.error("First Name Is Required");
            return false;
        }
        else if(user.LastName===""){
            toast.error("Last Name Is Required");
            return false;
        }
        else if(user.PhoneNumber===""){
            toast.error("Phone Number Is Required");
            return false;
        }
        return true;
    }
    const FetchProfile = async () => {
        setLoader(true);
        let response = await fetch(
          Variables.API_URL + "User/GetProfile/"+UserId,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin":Variables.API_URL
            },
          }
        );
        let result = await response.json();
        if (response.status === 200) {
          setUser({
            Username:result.Username,
            FirstName:result.FirstName,
            LastName:result.LastName,
            PhoneNumber:result.PhoneNumber,
            ProfilePicture:"",
            ProfilePicturePath:result.ProfilePicturePath

          });
          setLoader(false);
        } else {
          toast.error(result, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      
      };

    const handleProfilePicture = (e) => {
        var x = e.target.files[0];
        let reader = new FileReader();
        reader.onload = function (e) {
          if (
            e.target.result.indexOf("png") >= 0 ||
            e.target.result.indexOf("jpeg") >= 0 ||
            e.target.result.indexOf("jpg") >= 0
          ) {
              setUser((prevState) => ({
                ...prevState,
                ProfilePicture: e.target.result,
              }))
              setUser((prevState) => ({
                ...prevState,
                ProfilePicturePath: x,
              }))
          } else {
            toast.error("Invalid Image Type", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        };
        reader.readAsDataURL(e.target.files[0]);
      };
      const HandleSubmit = async () => {
        if (IsValid()) {
          let response = await fetch(Variables.API_URL + "User/UpdateProfile/"+UserId, {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
             Username:user.Username,
             FirstName:user.FirstName,
             LastName:user.LastName,
             PhoneNumber:user.PhoneNumber
            }),
          });
          let result = await response.json();
          
          if (response.status === 200) {
            if(user.ProfilePicture !== ""){
              const formData = new FormData();
              formData.append("Image", user.ProfilePicturePath);
              formData.append("FolderName", "User");
      
              const resImage = await axios.post(
                Variables.API_URL + "Media/UploadImageToServer",
                formData
              );
              if (resImage.data !== "") {
                // insert in media
                let responseMedia = await fetch(
                  Variables.API_URL + "Media/InsertMedia",
                  {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      ImageTitle: "",
                      AltText: "",
                      Description: "",
                      Caption: "",
                      Url: resImage.data,
                    }),
                  }
                );
                let resultInsertMedia = await responseMedia.json();
                if (responseMedia.status === 200) {
                  let responsePath = await fetch(
                    Variables.API_URL + "User/UpdateProfilePicture/" + UserId,
                    {
                      method: "PUT",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        ProfilePicturePath: resultInsertMedia,
                      }),
                    }
                  );
                  if(responsePath.ok===true){
                      toast.success("Updated Successfully");
                     
                  }
                }
              }
            }
            window.location.href="/Profile";
            toast.success(result, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(result, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } 
      };
  
      useEffect(() => {
       FetchProfile()
      }, []);
  return (
    <>
    <Row>
      <Col xl="12" lg="12">
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Manage Profile</h4>
            </div>
            <div>
             
              {"  "}
              <Button variant="btn buttonMeatcity" 
              onClick={HandleSubmit}
              >
                Submit
              </Button>
            </div>
          </Card.Header>
          {loader === true ? (
                <Col lg="6" md="6" xs="12" className="allignCenter">
            <CustomSnipper />
            </Col>
          ) : (
            <Card.Body>
              <div className="bd-example">
                <Row>
                <Col lg="6" md="6" xs="12">
                    <label> Username </label>
                    <input type="text" value={user.Username} className="form-control"  onChange={(e) =>
                            setUser((prevState) => ({
                              ...prevState,
                              Username: e.target.value,
                            }))
                          }/>
                    </Col>
                    <Col lg="6" md="6" xs="12">
                    <label> First Name </label>
                    <input type="text" value={user.FirstName} className="form-control"  onChange={(e) =>
                            setUser((prevState) => ({
                              ...prevState,
                              FirstName: e.target.value,
                            }))
                          }/>
                    </Col>
                    <Col lg="6" md="6" xs="12">
                    <label> Last Name </label>
                    <input type="text" value={user.LastName} className="form-control"  onChange={(e) =>
                            setUser((prevState) => ({
                              ...prevState,
                              LastName: e.target.value,
                            }))
                          }/>
                    </Col>
                    <Col lg="6" md="6" xs="12">
                    <label> Phone Number </label>
                    <input type="text" value={user.PhoneNumber} className="form-control"  onChange={(e) =>
                            setUser((prevState) => ({
                              ...prevState,
                              PhoneNumber: e.target.value,
                            }))
                          }/>
                    </Col>
                    <Col lg="6" md="6" xs="12">
                    <label> Profile Picture </label>
                   <input type="file"  accept="image/*" className="form-control" onChange={handleProfilePicture}/>
                    </Col>
                    <Col lg="6" md="6" xs="12" className="allignCenter">
{user.ProfilePicture === "" ? (
  <div>

    <img
      src={Variables.ImagePath + user.ProfilePicturePath}
      className="w-100 shadow-1-strong rounded mb-4 img-200 image-log"
    />
  </div>
) : (
  <div>
    <img
      src={user.ProfilePicture}
      className="w-100 shadow-1-strong rounded mb-4 img-200 image-log"
      alt="Please Select Image"
    />
  </div>
)}

</Col>
                </Row>
           
                
              
              
              
               
              </div>
            </Card.Body>
          )}
        </Card>
      </Col>
    </Row>
    <ToastContainer
      position="top-right"
      autoClose={1000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </>
  )
}

export default Profile