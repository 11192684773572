import React, { useState } from "react";
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card";
import validator from "validator";
import Util from "../../Classes/Util";
import { Variables } from "../../variables/Variables";
// img
import facebook from "../../assets/images/brands/fb.svg";
import google from "../../assets/images/brands/gm.svg";
import instagram from "../../assets/images/brands/im.svg";
import linkedin from "../../assets/images/brands/li.svg";
import auth1 from "../../assets/images/auth/01.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignIn = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailFormatError, setEmailFormatError] = useState(false);
  const [emailRequiredError, setEmailRequiredError] = useState(false);
  const [passwordRequiredError, setPasswordRequiredError] = useState(false);
  const [recaptchaVisible, SetRecaptchaVisible] = useState(false);
  const [isVerified, setisVerified] = useState(true);
  const [loader, setLoader] = useState(false);
  var key = "kNMG1HX4GMUIpOVIS0UBTzWREQkPApTt";
  var CryptoJS = require("crypto-js");
  const navigate = useNavigate();

  //handle email change
  const handleEmail = (e) => {
    const { name, value } = e.target;
    if (validator.isEmail(value)) {
      setEmailFormatError(false);
    }
    if (value === "") {
      setEmailRequiredError(true);
      setEmailFormatError(false);
    } else {
      setEmailRequiredError(false);
    }
    setEmail(value);
  };

  //handle password change
  const handlePassword = (e) => {
    const { value } = e.target;
    if (value === "") {
      setPasswordRequiredError(true);
    } else {
      setPasswordRequiredError(false);
    }
    setPassword(value);
  };

  // check input validity
  function IsValid() {
    var hasError = false;
    if (email == null || email == "") {
      setEmailRequiredError(true);
      hasError = true;
    } else if (!validator.isEmail(email)) {
      setEmailFormatError(true);
      hasError = true;
    }
    if (password == null || password == "") {
      setPasswordRequiredError(true);
      hasError = true;
    }
    if (hasError === true) {
      return false;
    } else {
      return true;
    }
  }

  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     draggable: true,
  //     progress: undefined,
  //   });
  //   setisVerified(false);
  //   SetRecaptchaVisible(false);
  //   SetRecaptchaVisible(true);
  //   setLoader(false)
  // }
  // }
  // }
  // unverified recaptcha
  // else {
  //   toast.error("Please resolve recaptcha", {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     draggable: true,
  //     progress: undefined,
  //   });
  // }
  // setLoader(false)

  //Login function
  const Login = async () => {
    setLoader(true);
    var isValid = IsValid();
    // case of recaptcha verified
    // if (isVerified) {
      //case of valid input
      if (isValid === true) {
        //fetch
        let response = await fetch(Variables.API_URL + "User/UserLogin", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        });
        let result = await response.json();
        //case of success
        if (response.status === 200) {
          localStorage.setItem("Email", Util.encrypt(result.Email)); // set email in localStorage
          localStorage.setItem("Permissions", Util.encrypt(result.Permissions)); // set permissions in localStorage
          localStorage.setItem("LoginToken", Util.encrypt(result.LoginToken)); // set Login Token in localStorage
          localStorage.setItem("UserId", Util.encrypt(result.UserId)); // set UserId in localStorage
          localStorage.setItem("Username", Util.encrypt(result.UserName)); // set Username in localStorage
          localStorage.setItem("Picture", Util.encrypt(result.Picture)); // set Picture in localStorage
          props.setIsAuth(true);
          setisVerified(true);
          setLoader(false);
          navigate("/Dashboard");
        }
        // case of valid temp password
        else if (response.status === 403) {
          toast.error(result, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          // we redirect the user for reset password
          localStorage.setItem("paOd", Util.encrypt(password)); // set old pass in localStorage
          localStorage.setItem("AEMl", Util.encrypt(email)); // set email in localStorage
          setLoader(false);
          navigate("/ResetPassword");
        }

        //invalid email or password
        else {
          toast.error(result, {
            position: "top-right",

            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          setisVerified(false);
          SetRecaptchaVisible(false);
          SetRecaptchaVisible(true);
          setLoader(false);
        }
      }
      setLoader(false);
    // }
    // unverified recaptcha
    // else {
    //   toast.error("Please resolve recaptcha", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
    setLoader(false);
  };

  return (
    <>
      <section className="login-content">
        <Row className="m-0 align-items-center bg-white vh-100">
          <Col md="6">
            <Row className="justify-content-center">
              <Col md="10">
                <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                  <Card.Body>
                    <Link
                      to="/"
                      className="navbar-brand d-flex align-items-center mb-3"
                    >
                     
                     <img width="30" viewBox="0 0 30 30" fill="none" src="../../../meatcityLogo.png"/>
                      <h4 className="logo-title ms-3">Bahia Khanoum | Admin</h4>
                    </Link>
                    <h2 className="mb-2 text-center" >Sign In</h2>
                    <p className="text-center">Login to stay connected.</p>
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <Row>
                        <Col lg="12">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="email" className="">
                              Email
                            </Form.Label>
                            <Form.Control
                              type="email"
                              className=""
                              id="email"
                              aria-describedby="email"
                              placeholder=" "
                              value={email}
                              onChange={handleEmail}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="12" className="">
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="password" className="">
                              Password
                            </Form.Label>
                            <Form.Control
                              type="password"
                              className=""
                              id="password"
                              aria-describedby="password"
                              placeholder=" "
                              value={password}
                              onChange={handlePassword}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg="12" className="d-flex justify-content-between">
                          {/* <Form.Check className="form-check mb-3">
                            <Form.Check.Input
                              type="checkbox"
                              id="customCheck1"
                            />
                            <Form.Check.Label htmlFor="customCheck1">
                              Remember Me
                            </Form.Check.Label>
                          </Form.Check> */}
                          <Link to="/forgetPassword" style={{color:"#d80f25"}}>Forgot Password?</Link>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-center">
                        <Button
                          onClick={() => Login()}
                          className="mt-3"
                          type="button"
                          variant="btn buttonMeatcity"
                        >
                          {loader === true ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </>
                          ) : (
                            "Sign In"
                          )}
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
        
          </Col>
          <Col
            md="6"
            className="d-md-block d-none  p-0 mt-n1 vh-100 overflow-hidden"
          >
            <Image
             src="../../../meatcityLogo.png"
              className="Image-fluid gradient-main animated-scaleX"
              alt="images"
            />
          </Col>
        </Row>
      </section>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default SignIn;
