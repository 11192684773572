import React, { useState,useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import CustomSnipper from "../../../components/customSnipper";
import { useNavigate, useParams } from "react-router-dom";
import { Variables } from "../../../variables/Variables";
const ManageRole = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  let {RoleId}=useParams()
  const [role, setRole] = useState({
    IsActive: true,
    RoleName: "",
    Dashboard: false,
    ViewBranches: false,
    AddBranch: false,
    UpdateBranch: false,
    ActiveBranch: false,
    ViewIngredients: false,
    AddIngredient: false,
    UpdateIngredient: false,
    TogglePublishIngredient:false,
    ViewProducts: false,
    AddProduct: false,
    UpdateProduct: false,
    ToggleStockProduct: false,
    PublishProduct: false,
    ViewMenus: false,
    AddMenu: false,
    UpdateMenu: false,
    PublishMenu: false,
    ViewOrders: false,
    UpdateOrder: false,
    ViewSettings: false,
    ViewLanguages: false,
    AddLanguage: false,
    UpdateLanguage: false,
    ToggleDefaultLanguage: false,
    TogglePublishLanguage:false,
    ViewUsers: false,
    AddUser: false,
    UpdateUser: false,
    ToggleLockUser: false,
    ViewRoles: false,
    AddRole: false,
    UpdateRole: false,
    ToggleRoleActivation: false,
    ViewCustomers:false,
    ToggleLockCustomer:false,
    ManageCustomerDetails:false,
    ManageCustomerAddressBooks:false,
    ViewSizes:false,
    AddSize:false,
    UpdateSize:false,
    TogglePublishSize:false,
  });

  const fetchRole = async () => {
    let response = await fetch(
      Variables.API_URL + "Role/GetRole/" + RoleId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
    setRole(result);
    console.log(result)
    }
  };
  function IsValid(){
      if(role.RoleName===""){
          toast.error("Name Is Required");
          return false;
      }
      return true
  }
  function handleSettings(e){
    setRole((prevState) => ({
      ...prevState,
      ViewSettings: e.target.checked,
    }));
    if(e.target.checked==false){
      setRole((prevState) => ({
        ...prevState,
        ViewLanguages: e.target.checked,
      }));
      setRole((prevState) => ({
        ...prevState,
        AddLanguage: e.target.checked,
      }));
      setRole((prevState) => ({
        ...prevState,
        UpdateLanguage: e.target.checked,
      }));
      setRole((prevState) => ({
        ...prevState,
        ToggleDefaultLanguage: e.target.checked,
      }));
      setRole((prevState) => ({
        ...prevState,
        TogglePublishLanguage: e.target.checked,
      }));

      setRole((prevState) => ({
        ...prevState,
        ViewUsers: e.target.checked,
      }));
      setRole((prevState) => ({
        ...prevState,
        AddUser: e.target.checked,
      }));
      setRole((prevState) => ({
        ...prevState,
        UpdateUser: e.target.checked,
      }));
      setRole((prevState) => ({
        ...prevState,
        ToggleLockUser: e.target.checked,
      }));
      setRole((prevState) => ({
        ...prevState,
        ViewRoles: e.target.checked,
      }));
      setRole((prevState) => ({
        ...prevState,
        AddRole: e.target.checked,
      }));
      setRole((prevState) => ({
        ...prevState,
        UpdateRole: e.target.checked,
      }));
      setRole((prevState) => ({
        ...prevState,
        ToggleRoleActivation: e.target.checked,
      }));


    }
  }
  const Submit = async () => {
    if(IsValid()){
      if (RoleId == 0) {
        let response = await fetch(Variables.API_URL + "Role/AddRole", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Variables.HostAPI,
          },
          body: JSON.stringify({
            RoleName: role.RoleName,
            IsActive:true,
        Dashboard: role.Dashboard,
        ViewBranches: role.ViewBranches,
        AddBranch: role.AddBranch,
        UpdateBranch: role.UpdateBranch,
        ActiveBranch: role.ActiveBranch,
        ViewIngredients: role.ViewIngredients,
        AddIngredient: role.AddIngredient,
        UpdateIngredient: role.UpdateIngredient,
        TogglePublishIngredient:role.TogglePublishIngredient,
        ViewProducts: role.ViewProducts,
        AddProduct: role.AddProduct,
        UpdateProduct: role.UpdateProduct,
        ToggleStockProduct: role.ToggleStockProduct,
        PublishProduct: role.PublishProduct,
        ViewMenus: role.ViewMenus,
        AddMenu: role.AddMenu,
        UpdateMenu: role.UpdateMenu,
        PublishMenu: role.PublishMenu,
        ViewOrders: role.ViewOrders,
        UpdateOrder: role.UpdateOrder,
        ViewSettings: role.ViewSettings,
        ViewLanguages: role.ViewLanguages,
        AddLanguage: role.AddLanguage,
        UpdateLanguage: role.UpdateLanguage,
        TogglePublishLanguage:role.TogglePublishLanguage,
        ToggleDefaultLanguage: role.ToggleDefaultLanguage,
        ViewUsers: role.ViewUsers,
        AddUser: role.AddUser,
        UpdateUser: role.UpdateUser,
        ToggleLockUser: role.ToggleLockUser,
        ViewRoles: role.ViewRoles,
        AddRole: role.AddRole,
        UpdateRole: role.UpdateRole,
        ToggleRoleActivation: role.ToggleRoleActivation,
        ViewCustomers:role.ViewCustomers,
        ToggleLockCustomer:role.ToggleLockCustomer,
        ManageCustomerDetails:role.ManageCustomerDetails,
        ManageCustomerAddressBooks:role.ManageCustomerAddressBooks,
        ViewSizes:role.ViewSizes,
    AddSize:role.AddSize,
    UpdateSize:role.UpdateSize,
    TogglePublishSize:role.TogglePublishSize,
          }),
        });
        if (response.ok === false) {
          response = await response.json();
          toast.error(response);
        } else {
          toast.success("Successfully Added");
          setTimeout(() => {
            navigate("/Roles");
          }, 1000);
        }
      } else {
        let response = await fetch(
          Variables.API_URL + "Role/UpdateRole/" + RoleId,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Variables.HostAPI,
            },
            body: JSON.stringify({
              RoleName: role.RoleName,
          Dashboard: role.Dashboard,
          ViewBranches: role.ViewBranches,
          AddBranch: role.AddBranch,
          UpdateBranch: role.UpdateBranch,
          ActiveBranch: role.ActiveBranch,
          ViewIngredients: role.ViewIngredients,
          AddIngredient: role.AddIngredient,
          UpdateIngredient: role.UpdateIngredient,
          TogglePublishIngredient:role.TogglePublishIngredient,
          ViewProducts: role.ViewProducts,
          AddProduct: role.AddProduct,
          UpdateProduct: role.UpdateProduct,
          ToggleStockProduct: role.ToggleStockProduct,
          PublishProduct: role.PublishProduct,
          ViewMenus: role.ViewMenus,
          AddMenu: role.AddMenu,
          UpdateMenu: role.UpdateMenu,
          PublishMenu: role.PublishMenu,
          ViewOrders: role.ViewOrders,
          UpdateOrder: role.UpdateOrder,
          ViewSettings: role.ViewSettings,
          ViewLanguages: role.ViewLanguages,
          AddLanguage: role.AddLanguage,
          UpdateLanguage: role.UpdateLanguage,
          TogglePublishLanguage:role.TogglePublishLanguage,
          ToggleDefaultLanguage: role.ToggleDefaultLanguage,
          ViewUsers: role.ViewUsers,
          AddUser: role.AddUser,
          UpdateUser: role.UpdateUser,
          ToggleLockUser: role.ToggleLockUser,
          ViewRoles: role.ViewRoles,
          AddRole: role.AddRole,
          UpdateRole: role.UpdateRole,
          ToggleRoleActivation: role.ToggleRoleActivation,
          ViewCustomers:role.ViewCustomers,
          ToggleLockCustomer:role.ToggleLockCustomer,
          ManageCustomerDetails:role.ManageCustomerDetails,
          ManageCustomerAddressBooks:role.ManageCustomerAddressBooks,
          ViewSizes:role.ViewSizes,
          AddSize:role.AddSize,
          UpdateSize:role.UpdateSize,
          TogglePublishSize:role.TogglePublishSize,
            }),
          }
        );
        if (response.ok === false) {
          response = await response.json();
          toast.error(response);
        } else {
          toast.success("Successfully Updated");
          setTimeout(() => {
            navigate("/Roles");
          }, 1000);
        }
      }
    }

  };

  useEffect(() => {
    if(RoleId!=0){
        fetchRole()
    }
}, []);

  return (
    <>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Manage Role</h4>
              </div>
              <div>
                <Button variant="secondary" onClick={() => navigate("/Roles")}>
                  Cancel
                </Button>
                {"  "}
                <Button
                  variant="btn buttonMeatcity"
                    onClick={Submit}
                >
                  Submit
                </Button>
              </div>
            </Card.Header>
            {loader === true ? (
              <CustomSnipper />
            ) : (
              <Card.Body>
                <div className="bd-example">
                  <Row>
                    <Col lg="6" md="6" sm="12">
                      <label> Role Name</label>
                      <input
                        type="text"
                        value={role.RoleName}
                        onChange={(e) =>
                          setRole((prevState) => ({
                            ...prevState,
                            RoleName: e.target.value,
                          }))
                        }
                        className="form-control"
                        placeholder="Role Name"
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <label>Dashboard</label>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check col-3 ml-1 mt-4">
                        <input
                          id="Dashboard"
                          type="checkbox"
                          className="form-check-input"
                          value={role.Dashboard}
                          checked={role.Dashboard}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              Dashboard: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="Dashboard">
                          Dashboard
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <label>Branches</label>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ViewBranches"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ViewBranches}
                          checked={role.ViewBranches}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ViewBranches: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="ViewBranches">
                          View Branches
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="AddBranch"
                          type="checkbox"
                          className="form-check-input"
                          value={role.AddBranch}
                          checked={role.AddBranch}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              AddBranch: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="AddBranch">
                          Add Branch
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="UpdateBranch"
                          type="checkbox"
                          className="form-check-input"
                          value={role.UpdateBranch}
                          checked={role.UpdateBranch}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              UpdateBranch: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="UpdateBranch">
                          Update Branch
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ActiveBranch"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ActiveBranch}
                          checked={role.ActiveBranch}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ActiveBranch: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="ActiveBranch">
                          Toggle Activation
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <label>Ingredients</label>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ViewIngredients"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ViewIngredients}
                          checked={role.ViewIngredients}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ViewIngredients: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="ViewIngredients">
                          View Ingredients
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="AddIngredient"
                          type="checkbox"
                          className="form-check-input"
                          value={role.AddIngredient}
                          checked={role.AddIngredient}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              AddIngredient: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="AddIngredient">
                          Add Ingredient
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="UpdateIngredient"
                          type="checkbox"
                          className="form-check-input"
                          value={role.UpdateIngredient}
                          checked={role.UpdateIngredient}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              UpdateIngredient: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="UpdateIngredient">
                          Update Ingredient
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="TogglePublishIngredient"
                          type="checkbox"
                          className="form-check-input"
                          value={role.TogglePublishIngredient}
                          checked={role.TogglePublishIngredient}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              TogglePublishIngredient: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="TogglePublishIngredient">
                        Toggle Publish Ingredient
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <br />

                  <br />
                  <Row>
                    <label>Sizes</label>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ViewSizes"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ViewSizes}
                          checked={role.ViewSizes}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ViewSizes: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="ViewSizes">
                          View Sizes
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="AddSize"
                          type="checkbox"
                          className="form-check-input"
                          value={role.AddSize}
                          checked={role.AddSize}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              AddSize: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="AddSize">
                          Add Size
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="UpdateSize"
                          type="checkbox"
                          className="form-check-input"
                          value={role.UpdateSize}
                          checked={role.UpdateSize}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              UpdateSize: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="UpdateSize">
                          Update Size
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="TogglePublishSize"
                          type="checkbox"
                          className="form-check-input"
                          value={role.TogglePublishSize}
                          checked={role.TogglePublishSize}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              TogglePublishSize: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="TogglePublishSize">
                        Toggle Publish Size
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <label>Products</label>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ViewProducts"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ViewProducts}
                          checked={role.ViewProducts}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ViewProducts: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="ViewProducts">
                          View Products
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="AddProduct"
                          type="checkbox"
                          className="form-check-input"
                          value={role.AddProduct}
                          checked={role.AddProduct}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              AddProduct: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="AddProduct">
                          Add Product
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="UpdateProduct"
                          type="checkbox"
                          className="form-check-input"
                          value={role.UpdateProduct}
                          checked={role.UpdateProduct}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              UpdateProduct: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="UpdateProduct">
                          Update Product
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ToggleStockProduct"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ToggleStockProduct}
                          checked={role.ToggleStockProduct}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ToggleStockProduct: e.target.checked,
                            }))
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ToggleStockProduct"
                        >
                          Toggle Stock
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="PublishProduct"
                          type="checkbox"
                          className="form-check-input"
                          value={role.PublishProduct}
                          checked={role.PublishProduct}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              PublishProduct: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="PublishProduct">
                          Toggle Publish
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <label>Menus</label>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ViewMenus"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ViewMenus}
                          checked={role.ViewMenus}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ViewMenus: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="ViewMenus">
                          View Menus
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="AddMenu"
                          type="checkbox"
                          className="form-check-input"
                          value={role.AddMenu}
                          checked={role.AddMenu}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              AddMenu: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="AddMenu">
                          Add Menu
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="UpdateMenu"
                          type="checkbox"
                          className="form-check-input"
                          value={role.UpdateMenu}
                          checked={role.UpdateMenu}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              UpdateMenu: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="UpdateMenu">
                          Update Menu
                        </label>
                      </div>
                    </Col>

                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="PublishMenu"
                          type="checkbox"
                          className="form-check-input"
                          value={role.PublishMenu}
                          checked={role.PublishMenu}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              PublishMenu: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="PublishMenu">
                          Toggle Publish
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <label>Orders</label>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ViewOrders"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ViewOrders}
                          checked={role.ViewOrders}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ViewOrders: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="ViewOrders">
                          View Orders
                        </label>
                      </div>
                    </Col>

                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="UpdateOrder"
                          type="checkbox"
                          className="form-check-input"
                          value={role.UpdateOrder}
                          checked={role.UpdateOrder}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              UpdateOrder: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="UpdateOrder">
                          Update Order
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <label>Customers</label>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ViewCustomers"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ViewCustomers}
                          checked={role.ViewCustomers}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ViewCustomers: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="ViewCustomers">
                          View Customers
                        </label>
                      </div>
                    </Col>

                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ToggleLockCustomer"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ToggleLockCustomer}
                          checked={role.ToggleLockCustomer}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ToggleLockCustomer: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="ToggleLockCustomer">
                        Toggle Lock Customer
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ManageCustomerDetails"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ManageCustomerDetails}
                          checked={role.ManageCustomerDetails}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ManageCustomerDetails: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="ManageCustomerDetails">
                        Manage Customer Details
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ManageCustomerAddressBooks"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ManageCustomerAddressBooks}
                          checked={role.ManageCustomerAddressBooks}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ManageCustomerAddressBooks: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="ManageCustomerAddressBooks">
                        Manage Customer Address Books
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <br />


                  <Row>
                    <label>Settings</label>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ViewSettings"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ViewSettings}
                          checked={role.ViewSettings}
                          onChange={(e) =>handleSettings(e)    }
                        />
                        <label className="form-check-label" htmlFor="ViewSettings">
                          View Settings
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <label>Languages</label>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ViewLanguages"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ViewLanguages}
                          checked={role.ViewLanguages}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ViewLanguages: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="ViewLanguages">
                          View Languages
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="AddLanguage"
                          type="checkbox"
                          className="form-check-input"
                          value={role.AddLanguage}
                          checked={role.AddLanguage}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              AddLanguage: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="AddLanguage">
                          Add Language
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="UpdateLanguage"
                          type="checkbox"
                          className="form-check-input"
                          value={role.UpdateLanguage}
                          checked={role.UpdateLanguage}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              UpdateLanguage: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="UpdateLanguage">
                          Update Language
                        </label>
                      </div>
                    </Col>

                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ToggleDefaultLanguage"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ToggleDefaultLanguage}
                          checked={role.ToggleDefaultLanguage}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ToggleDefaultLanguage: e.target.checked,
                            }))
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ToggleDefaultLanguage"
                        >
                          Toggle Default
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="TogglePublishLanguage"
                          type="checkbox"
                          className="form-check-input"
                          value={role.TogglePublishLanguage}
                          checked={role.TogglePublishLanguage}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              TogglePublishLanguage: e.target.checked,
                            }))
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="TogglePublishLanguage"
                        >
                          Toggle Publish
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <label>Users</label>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ViewUsers"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ViewUsers}
                          checked={role.ViewUsers}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ViewUsers: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="ViewUsers">
                          View Users
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="AddUser"
                          type="checkbox"
                          className="form-check-input"
                          value={role.AddUser}
                          checked={role.AddUser}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              AddUser: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="AddUser">
                          Add User
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="UpdateUser"
                          type="checkbox"
                          className="form-check-input"
                          value={role.UpdateUser}
                          checked={role.UpdateUser}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              UpdateUser: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="UpdateUser">
                          Update User
                        </label>
                      </div>
                    </Col>

                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ToggleLockUser"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ToggleLockUser}
                          checked={role.ToggleLockUser}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ToggleLockUser: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="ToggleLockUser">
                          Toggle Lock
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <label>Roles</label>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ViewRoles"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ViewRoles}
                          checked={role.ViewRoles}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ViewRoles: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="ViewRoles">
                          View Roles
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="AddRole"
                          type="checkbox"
                          className="form-check-input"
                          value={role.AddRole}
                          checked={role.AddRole}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              AddRole: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="AddRole">
                          Add Role
                        </label>
                      </div>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="UpdateRole"
                          type="checkbox"
                          className="form-check-input"
                          value={role.UpdateRole}
                          checked={role.UpdateRole}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              UpdateRole: e.target.checked,
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="UpdateRole">
                          Update Role
                        </label>
                      </div>
                    </Col>

                    <Col lg="3" md="6" sm="12">
                      <div className="form-check  ml-1 mt-4">
                        <input
                          id="ToggleRoleActivation"
                          type="checkbox"
                          className="form-check-input"
                          value={role.ToggleRoleActivation}
                          checked={role.ToggleRoleActivation}
                          onChange={(e) =>
                            setRole((prevState) => ({
                              ...prevState,
                              ToggleRoleActivation: e.target.checked,
                            }))
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ToggleRoleActivation"
                        >
                          Toggle Activation
                        </label>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            )}
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ManageRole;
