import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Tab,
  Nav,
  Modal,
  Form,
  InputGroup,
  FormControl,
  FormCheck,
} from "react-bootstrap";
import CustomSnipper from "../../components/customSnipper";
import { useNavigate, Link, Navigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Variables } from "../../variables/Variables";
import axios from "axios";
import Util from "../../Classes/Util";
function ManageMenu() {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const RouteMenuId = useParams();
  const [menuId, setMenuId] = useState(Number(RouteMenuId.RouteMenuId) || 0);

  const [menuDetails, setMenuDetails] = useState({
    MenuId: 0,
    FixedMenuItems: {
      IsPublished: true,
      image: 0,
      Url: "",
    },
    UnFixedMenuItems: [
      {
        Description: "",
        Name: "",
        MenuLanguageId: 0,
        LanguageId: 0,
        LanguageName: "",
        LanguageSuffix: "",
      },
    ],
  });

  const [imageToDb, setImageToDb] = useState("");
  const [image, setImage] = useState("");
  const [languagesToAdd, setLanguagesToadd] = useState([]);

  // Get Menu Details
  const GetMenuDetails = async () => {
    let response = await fetch(Variables.API_URL + "Menu/GetMenu/" + menuId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setMenuDetails(result);
    }
  };

  // Get Languages To Add
  const GetLanguagesToAdd = async () => {
    let response = await fetch(
      Variables.API_URL + "Menu/GetLanguagesToAddMenu",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setMenuDetails(result);
    }
  };

  // Handle name change
  const HandleNameChange = (e, index) => {
    var detailsCopy = menuDetails;
    var langArray = [...detailsCopy.UnFixedMenuItems];
    var toUpdate = langArray[index];
    delete langArray[index];
    toUpdate["Name"] = e.target.value;
    langArray[index] = toUpdate;

    var x = {
      MenuId: menuId,
      FixedMenuItems: {
        IsPublished: true,
        Image: 0,
        Url: menuDetails.FixedMenuItems.Url,
      },
      UnFixedMenuItems: langArray,
    };
    setMenuDetails(x);
  };

  // Handle name change
  const HandleDescriptionChange = (e, index) => {
    var detailsCopy = menuDetails;
    var langArray = [...detailsCopy.UnFixedMenuItems];
    var toUpdate = langArray[index];
    delete langArray[index];
    toUpdate["Description"] = e.target.value;
    langArray[index] = toUpdate;

    var x = {
      MenuId: menuId,
      FixedMenuItems: {
        IsPublished: true,
        Image: 0,
        Url: menuDetails.FixedMenuItems.Url,
      },
      UnFixedMenuItems: langArray,
    };
    setMenuDetails(x);
  };

  // setimage
  const SetImage = (e) => {
    var x = e.target.files[0];
    let reader = new FileReader();
    reader.onload = function (e) {
      if (
        e.target.result.indexOf("png") >= 0 ||
        e.target.result.indexOf("jpeg") >= 0 ||
        e.target.result.indexOf("jpg") >= 0
      ) {
        setImageToDb(x);
        setImage(e.target.result);
      } else {
        toast.error("File type error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  // Handle Menu Publish
  const HandleMenuPublish = () => {
    var x;
    if (menuDetails.FixedMenuItems.IsPublished === true) {
      x = {
        MenuId: menuId,
        FixedMenuItems: {
          IsPublished: false,
          Image: 0,
          Url: menuDetails.FixedMenuItems.Url,
        },
        UnFixedMenuItems: menuDetails.UnFixedMenuItems,
      };
    } else {
      x = {
        MenuId: menuId,
        FixedMenuItems: {
          IsPublished: true,
          Image: 0,
          Url: menuDetails.FixedMenuItems.Url,
        },
        UnFixedMenuItems: menuDetails.UnFixedMenuItems,
      };
    }
    setMenuDetails(x);
  };

  // Is Valid To Add
  function IsValid() {
    debugger;
    var hasError = false;

    if (imageToDb === "" && menuDetails.FixedMenuItems.Url==="" ) {
      //var ele = document.getElementById("nameToAdd" + counter);
      //ele.classList.add("is-invalid");
      hasError = true;
    }

    var counter = 0;
    menuDetails.UnFixedMenuItems.forEach((element) => {
      if (element.Description === "" || element.Description === null) {
        var ele = document.getElementById("Description" + counter);
        ele.classList.add("is-invalid");
        hasError = true;
      }

      if (element.Name === "" || element.Name === null) {
        var ele = document.getElementById("Name" + counter);
        ele.classList.add("is-invalid");
        hasError = true;
      }
      counter++;
    });

    if (hasError) {
      return false;
    } else {
      return true;
    }
  }

  // handle Update
  const handleUpdate = async () => {
    debugger;
    if (IsValid()) {
      let response = await fetch(Variables.API_URL + "Menu/UpdateMenu", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        //body: formData,
        body: JSON.stringify({
          MenuId:menuDetails.MenuId,
          FixedMenuItems: menuDetails.FixedMenuItems,
          UnFixedMenuItems: menuDetails.UnFixedMenuItems,
        }),
      });
      let result = await response.json();
      if (response.status === 200) {
        navigate("/Menus");
       if(imageToDb!=""){
        const formData = new FormData();
        formData.append("Image", imageToDb);
        formData.append("FolderName", "Menu");

        const resImage = await axios.post(
          Variables.API_URL + "Media/UploadImageToServer",
          formData
        );
        if (resImage.data !== "") {
          // insert in media
          let responseMedia = await fetch(
            Variables.API_URL + "Media/InsertMedia",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                ImageTitle: "",
                AltText: "",
                Description: "",
                Caption: "",
                Url: resImage.data,
              }),
            }
          );
          let resultInsertMedia = await responseMedia.json();
          if (responseMedia.status === 200) {
            let responsePath = await fetch(
              Variables.API_URL + "Menu/UpdateMenuMedia/" +  menuDetails.MenuId,
              {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  Image: resultInsertMedia,
                }),
              }
            );
            let resultInsertPath = await responsePath.json();
          }
        }
       }

        toast.success("Menu Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Please Fill All Required Inputs", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

};


  // Handle Insert
  const HandleInsert = async () => {
    var valid = IsValid();
    if (valid) {
      let response = await fetch(Variables.API_URL + "Menu/AddMenu", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        //body: formData,
        body: JSON.stringify({
          FixedMenuItems: menuDetails.FixedMenuItems,
          UnFixedMenuItems: menuDetails.UnFixedMenuItems,
        }),
      });
      let result = await response.json();
      if (response.status === 200) {
        navigate("/Menus");
        const formData = new FormData();
        formData.append("Image", imageToDb);
        formData.append("FolderName", "Menu");

        const resImage = await axios.post(
          Variables.API_URL + "Media/UploadImageToServer",
          formData
        );
        if (resImage.data !== "") {
          // insert in media
          let responseMedia = await fetch(
            Variables.API_URL + "Media/InsertMedia",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                ImageTitle: "",
                AltText: "",
                Description: "",
                Caption: "",
                Url: resImage.data,
              }),
            }
          );
          let resultInsertMedia = await responseMedia.json();
          if (responseMedia.status === 200) {
            let responsePath = await fetch(
              Variables.API_URL + "Menu/UpdateMenuMedia/" + result,
              {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  Image: resultInsertMedia,
                }),
              }
            );
            let resultInsertPath = await responsePath.json();
          }
        }

        toast.success("Menu Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Please Fill All Required Inputs", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleSubmit = () => {
    if (menuId === 0) {
      HandleInsert();
    } else {
      handleUpdate();
    }
  };

  useEffect(() => {
    if (menuId !== 0) {
      GetMenuDetails();
    } else {
      GetLanguagesToAdd();
    }
  }, [menuId]);

  return (
    <>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title"> Manage Menu</h4>
              </div>
              <div>
                <Button variant="secondary" onClick={() => navigate("/Menus")}>
                  Cancel
                </Button>
                {"  "}
                <Button
                  variant="btn buttonMeatcity"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              </div>
            </Card.Header>
            {loader === true ? (
              <CustomSnipper />
            ) : (
              <Card.Body>
                <div className="bd-example">
                  <Row>
                    <Form.Group className="col-lg-6 col-md-12 col-sm-12 form-group">
                      <Row>
                        <Form.Group>
                          <Form.Label className="custom-file-input">
                            Choose Menu Image
                          </Form.Label>
                          <Form.Control
                            id="Image"
                            name="Image"
                            placeholder="Menu Image"
                            type="file"
                            accept="image/*"
                            onChange={SetImage}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Col className="allignCenter">
                          {imageToDb === "" ? (
                            <div>
                              <img
                                src={
                                  Variables.ImagePath +
                                  menuDetails.FixedMenuItems.Url
                                }
                                className="w-100 shadow-1-strong rounded mb-4 img-200 image-log"
                                alt="Menu Image"
                              />
                            </div>
                          ) : (
                            <div>
                              <img
                                src={image}
                                className="w-100 shadow-1-strong rounded mb-4 img-200 image-log"
                                alt="Menu Image"
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                    {permissions.PublishMenu && (
                      <Form.Group className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <Form.Label htmlFor="fname">.</Form.Label>
                        <Form.Check className="mb-3 form-check">
                          <FormCheck.Input
                            type="checkbox"
                            className=""
                            id="HasDeliveryStatusChangePermission"
                            checked={menuDetails.FixedMenuItems.IsPublished}
                            onChange={(e) => HandleMenuPublish()}
                          />
                          <FormCheck.Label className="" htmlFor="exampleCheck1">
                            Publish
                          </FormCheck.Label>
                        </Form.Check>
                      </Form.Group>
                    )}
                    {menuDetails.UnFixedMenuItems.map((data, i) => (
                      <Col lg="6" md="6" xs="12" key={i}>
                        <Card style={{ backgroundColor: "aliceblue" }}>
                          <Card.Header style={{ backgroundColor: "aliceblue" }}>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                <h4 className="px-3">
                                  Language {data.LanguageName}
                                </h4>
                              </div>
                              <div className="d-flex align-items-center justify-content-end"></div>
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <br />
                            <br />
                            <Form className="d-grid gap-card">
                              <Form.Group className="mb-2">
                                <Form.Label htmlFor="fname">
                                  Menu Name :
                                </Form.Label>
                                <InputGroup>
                                  <FormControl
                                    type="text"
                                    placeholder={
                                      "Menu Name in " + data.LanguageName
                                    }
                                    id={"Name" + i}
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon3"
                                    value={data.Name}
                                    onChange={(e) => HandleNameChange(e, i)}
                                  />
                                  <InputGroup.Text
                                    as="span"
                                    className="input-group-text"
                                    id="name"
                                  >
                                    {data.LanguageSuffix}
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>

                              <Form.Group className="mb-2">
                                <Form.Label htmlFor="fname">
                                  Menu Description :
                                </Form.Label>
                                <InputGroup>
                                  <FormControl
                                    type="text"
                                    placeholder={
                                      "Menu Decription in " + data.LanguageName
                                    }
                                    id={"Description" + i}
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon3"
                                    value={data.Description}
                                    onChange={(e) =>
                                      HandleDescriptionChange(e, i)
                                    }
                                  />
                                </InputGroup>
                              </Form.Group>
                              {/* {menuId !== 0 && <Button
                                variant="btn buttonMeatcity"
                                //onClick={(e) => HandleUpdate(i)}
                              >
                                Update
                              </Button>} */}
                            </Form>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}{" "}
                  </Row>
                </div>
              </Card.Body>
            )}
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default ManageMenu;
