import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate, Link } from "react-router-dom";
import { Variables } from "../../variables/Variables";
import { BiEdit } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import { FaLock, FaUnlockAlt } from "react-icons/fa";
import { AiOutlineClose ,AiFillLock,AiFillUnlock} from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";
import ReactTooltip from "react-tooltip";
import CustomSnipper from "../../components/customSnipper";
import Util from "../../Classes/Util";
const Customers = () => {
  // const permissions=Util.decrypt(localStorage.getItem("Permissions"));
  const [customers, setcustomers] = useState([]);
  const [filteredcustomers, setFilteredcustomers] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  //Lock/Unlock customers
  const ToggleLock = async (CustomerId) => {
    let response = await fetch(
      Variables.API_URL + "Customer/ToggleLock/" + CustomerId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    if (response.ok === false) {
      toast.error(result);
    } else {
      toast.success(result);
      setSearch("");
      Getcustomers();
    }
  };


  //fetch customers

  const Getcustomers = async () => {
    setLoader(true)
    let response = await fetch(Variables.API_URL + "Customer/Getcustomers", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin":Variables.API_URL
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setcustomers(result);
      setFilteredcustomers(result);
      setLoader(false);
    }
  };
  //handle Search by  Name
  const handleSearch = (e) => {
    setSearch(e.target.value);
    let value = e.target.value.toLowerCase();
    let result = [];
    if (value === "") {
      result = customers;
    } else {
      result = customers.filter((child) =>
        child.Name.toLowerCase().includes(value)
      );
    }

    setFilteredcustomers(result);
  };

  //Pagination
  const paginationComponentOptions = {
    rowsPerPageText: "View Records",
    rangeSeparatorText: "out",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };
  //customers columns

  const customersColumns = [
    {
      name: "#",
      selector: (row) => row.CustomerId,
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row.Name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.Email,
      sortable: true,
    },
   
    {
      name: "Locked",
      selector: (row) => 
      (<div>
        {row.IsLocked?<FcCheckmark /> : <AiOutlineClose />}
      </div>),
      sortable: true,
    },
   

    {
      name: "Actions",
      selector: (row) => (
        <div>

           {(permissions.ManageCustomerDetails || permissions.ManageCustomerAddressBooks) && ( <Link
                                  className="btn btn-sm btn-icon "
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  data-original-title="Edit"
                                  to={"/Customer/"+row.CustomerId}
                                >
                                 
                                    <BiEdit data-tip={"Manage "+row.Name}/>
                       
                                  <ReactTooltip
                      place="bottom"
                      className="tooltip"
                      effect="solid"
                    /> 
                                </Link>
        )}
          {permissions.ToggleLockCustomer && (<button
            className="btn btn-icon ml-5 "
            onClick={() => ToggleLock(row.CustomerId)}
          >
            {row.IsLocked ? (
              <AiFillUnlock data-tip="Unlock" />
            ) : (
              <AiFillLock data-tip="Lock" />
            )}
          </button>)}
        </div>
      ),
    },
  ];
  //#region Use Effect
  useEffect(() => {
    Getcustomers();
  }, []);

  //#endregion Use Effect
  return (
    <>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Customers List</h4>
              </div>
             
            </Card.Header>

            <Card.Body>
              <Row style={{ marginBottom: "20px" }}>
                <Col sm="6" md="4" lg="4" className="text-right">
                  <input
                    type="text"
                    onChange={(e) => handleSearch(e)}
                    className="form-control"
                    autoFocus={true}
                    placeholder="Search By Name"
                    value={search}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  {loader ? (
                    <CustomSnipper />
                  ) : (
                    <DataTable
                      columns={customersColumns}
                      data={filteredcustomers}
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                    />
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default Customers;
