import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Tab,
  Nav,
  Modal,
  Form,
  InputGroup,
  FormControl,
  FormCheck,
} from "react-bootstrap";
import CustomSnipper from "../../components/customSnipper";
import { useNavigate, Link, Navigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Variables } from "../../variables/Variables";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";

function ManageProduct() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const RouteProductId = useParams();
  const [productId, setProductId] = useState(
    Number(RouteProductId.RouteProductId) || 0
  );
  const current = new Date();
  const [fixedProductItems, setFixedProductItems] = useState({
    IsPublished: true,
    IsCombo: false,
    ComboPicture: 0,
    Sku: "",
    StockQuantity: 0,
    RegularPrice: 0,
    SalePrice: "",
    IsSaleSchedule: false,
    SaleStartDate: `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`,
    SaleEndDate: `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`,
    InStock: true,
    MenuId: 0,
    IsDeleted: false,
    ProductImage: 0,
    ProductImageUrl: "",
  });
  const [unFixedProductItems, setUnFixedProductItems] = useState([]);

  const [ingredients, setIngredients] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [unrequiredIngredients, setUnrequiredIngredients] = useState([]);
  const [addons, setAddons] = useState([]);

  const [menus, setMenus] = useState([]);
  const [productImage, setProductImage] = useState("");
  const [productImageToDb, setProductImageToDb] = useState("");
  const [productImagePath, setProductImagePath] = useState("");


  const[sizes,setSizes]=useState([]);
  const[selectedSizes,setSelectedSizes]=useState([])

  //Get Ingredients
  const GerIngredients = async () => {
    let response = await fetch(
      Variables.API_URL + "Ingredient/GetIngredients",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setIngredients(result);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  //Get Product Details
  const GetProductDetails = async () => {
    let response = await fetch(
      Variables.API_URL + "Product/GetProductDetails/" + productId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();

    if (response.status === 200) {
      setUnFixedProductItems(result.UnFixedProductItems);
      setSelectedIngredients(result.FixedProductItems.ProductIngredients);
      setUnrequiredIngredients(
        result.FixedProductItems.ProductUnrequiredIngredients
      );
      setAddons(result.FixedProductItems.AddOns);
      setFixedProductItems(result.FixedProductItems)
      setSelectedSizes(result.FixedProductItems.Sizes);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  //Get Sizes
  const GetSizes = async () => {
    let response = await fetch(
      Variables.API_URL + "Size/GetSizes",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setSizes(result);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Handle name change
  const HandleNameChange = (e, i) => {
    const { name, value } = e.target;

    if (value === "") {
      var ele = document.getElementById("name" + i);
      ele.classList.add("is-invalid");
    }
    var detailsCopy = [...unFixedProductItems];
    var detailsCopyToUpdate = detailsCopy[i];
    delete detailsCopy[i];
    detailsCopyToUpdate["Name"] = e.target.value;
    detailsCopy[i] = detailsCopyToUpdate;
    setUnFixedProductItems(detailsCopy);
  };

  // Handle name change
  const HandleDescriptionChange = (e, i) => {
    const { name, value } = e.target;

    if (value === "") {
      var ele = document.getElementById("Description" + i);
      ele.classList.add("is-invalid");
    }
    var detailsCopy = [...unFixedProductItems];
    var detailsCopyToUpdate = detailsCopy[i];
    delete detailsCopy[i];
    detailsCopyToUpdate["Description"] = e.target.value;
    detailsCopy[i] = detailsCopyToUpdate;
    setUnFixedProductItems(detailsCopy);
  };

  // Get Menus
  const GetMenus = async () => {
    let response = await fetch(Variables.API_URL + "Menu/GetMenus", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setMenus(result);
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Handle Input Fixed Items
  const HandleInputFixedItems = (e) => {
    const { name, value } = e.target;
    setFixedProductItems((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // handle Select Ingredient
  const handleSelectIngredient = (e) => {
    setSelectedIngredients(e);
  };

  // handle Select Unrequired Ingredient
  const handleSelectUnrequiredIngredient = (e) => {
    setUnrequiredIngredients(e);
  };

  // handle Select AddOns
  const handleSelectAddOns = (e) => {
    if (productId !== 0) {
      setAddons(e);
    } else {
      e.forEach((element) => {
        element.ExtraPrice = "";
      });
      setAddons(e);
    }
  };
  // handle Select Sizes
  const handleSelectSizes = (e) => {
    if (productId !== 0) {
      setSelectedSizes(e);
    } else {
      e.forEach((element) => {
        element.SizePrice = "";
      });
      setSelectedSizes(e);
    }
  };


  // Handle Has SalePrice
  const HandleHasSalePrice = (e) => {
    const { name, value } = e.target;
    if (value == "off" || value == "false") {
      setFixedProductItems((prevState) => ({
        ...prevState,
        [name]: true,
      }));
    }
    if (value == "on" || value == "true") {
      setFixedProductItems((prevState) => ({
        ...prevState,
        [name]: false,
      }));
    }
  };

  // Set Product Image
  const SetProductImage = (e) => {
    var x = e.target.files[0];
    let reader = new FileReader();
    reader.onload = function (e) {
      if (
        e.target.result.indexOf("png") >= 0 ||
        e.target.result.indexOf("jpeg") >= 0 ||
        e.target.result.indexOf("jpg") >= 0
      ) {
        setProductImageToDb(x);
        setProductImage(e.target.result);
      } else {
        toast.error("File type error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  // handle Addons extra price
  const handleAddonsExtraPrice = (e, i) => {
    const { name, value } = e.target;

    const copy = [...addons];
    const copyToUpdate = copy[i];
    delete copy[i];
    copyToUpdate["ExtraPrice"] = value;
    copy[i] = copyToUpdate;
    setAddons(copy);

    if (value == 0 || value === "") {
      var element = document.getElementById(name);
      element.classList.add("is-invalid");
      element.focus();
    } else {
      var element = document.getElementById(name);
      element.classList.remove("is-invalid");
    }
  };
  // handle Sizes extra price
  const handleSizesPrice = (e, i) => {
    const { name, value } = e.target;

    const copy = [...selectedSizes];
    const copyToUpdate = copy[i];
    delete copy[i];
    copyToUpdate["SizePrice"] = value;
    copy[i] = copyToUpdate;
    setSelectedSizes(copy);

    if (value == 0 || value === "") {
      var element = document.getElementById(name);
      element.classList.add("is-invalid");
      element.focus();
    } else {
      var element = document.getElementById(name);
      element.classList.remove("is-invalid");
    }
  };

  function IsValid() {
    var hasError = false;
    var counter = 0;
    unFixedProductItems.forEach((item) => {
      if (item.Name === "" || item.Name === null) {
        var element = document.getElementById("Name" + counter);
        element.classList.add("is-invalid");
        element.focus();
        hasError = true;
      }
      if (item.Description === "" || item.Description === null) {
        var element = document.getElementById("Description" + counter);
        element.classList.add("is-invalid");
        element.focus();
        hasError = true;
      }
      counter++;
    });

    if (fixedProductItems.MenuId == 0) {
      var element = document.getElementById("MenuId");
      element.classList.add("is-invalid");
      element.focus();
      hasError = true;
    }

    if (selectedIngredients.length == 0) {
      var element = document.getElementById("Ingredients").childNodes[0];
      element.classList.add("required");
      element.focus();
      hasError = true;
    }
if(selectedSizes.length==0){
    if (
      fixedProductItems.RegularPrice == 0 ||
      fixedProductItems.RegularPrice == ""
    ) {
      var element = document.getElementById("RegularPrice");
      element.classList.add("is-invalid");
      element.focus();
      hasError = true;
    }

    if (fixedProductItems.IsSaleSchedule === true) {
      if (
        fixedProductItems.SalePrice == 0 ||
        fixedProductItems.SalePrice == ""
      ) {
        var element = document.getElementById("SalePrice");
        element.classList.add("is-invalid");
        element.focus();
        hasError = true;
      }

      if (fixedProductItems.SaleEndDate == "") {
        var element = document.getElementById("SaleEndDate");
        element.classList.add("is-invalid");
        element.focus();
        hasError = true;
      }
    }


    }
    var counter1 = 0;
    if (addons.length > 0) {
      addons.forEach((item) => {
        if (
          item.ExtraPrice == 0 ||
          item.ExtraPrice == ""
        ) {
          var element = document.getElementById("ExtraPrice" + counter1);
          element.classList.add("is-invalid");
          element.focus();
          hasError = true;
        }
        counter1++;
      });
    }

    var counter2 = 0;
    if (selectedSizes.length > 0) {
      selectedSizes.forEach((item) => {
        if (
          item.SizePrice == 0 ||
          item.SizePrice == ""
        ) {
          var element = document.getElementById("SizePrice" + counter2);
          element.classList.add("is-invalid");
          element.focus();
          hasError = true;
        }
        counter2++;
      });
    }

    if (hasError === true) {
      return false;
    } else {
      return true;
    }
  }

  // Handle Insert
  const HandleInsert = async () => {
    var valid = IsValid();
    if (valid) {
      let response = await fetch(Variables.API_URL + "Product/AddProduct", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ProductId: productId,
          FixedProductItems: fixedProductItems,
          UnFixedProductItems: unFixedProductItems,
          ProductIngredients: selectedIngredients,
          ProductUnrequiredIngredients: unrequiredIngredients,
          AddOns: addons,     
          Sizes:selectedSizes     
        }),
      });
      let result = await response.json();
      if (response.status === 200) {
       setTimeout(() => {
        navigate("/Product")
       }, 1500);
        const formData = new FormData();
        formData.append("Image", productImageToDb);
        formData.append("FolderName", "Product");

        const resImage = await axios.post(
          Variables.API_URL + "Media/UploadImageToServer",
          formData
        );
        if (resImage.data !== "") {
          // insert in media
          let responseMedia = await fetch(
            Variables.API_URL + "Media/InsertMedia",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                ImageTitle: "",
                AltText: "",
                Description: "",
                Caption: "",
                Url: resImage.data,
              }),
            }
          );
          let resultInsertMedia = await responseMedia.json();
          if (responseMedia.status === 200) {
            let responsePath = await fetch(
              Variables.API_URL + "Product/UpdateProductMedia/" + result,
              {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  ProductImage: resultInsertMedia,
                }),
              }
            );
            let resultInsertPath = await responsePath.json();
           
          }
        }

        toast.success("Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Please Fill All Required Inputs", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Handle Insert
  const HandleUpdate = async () => {
    var valid = IsValid();
    if (valid) {
      let response = await fetch(Variables.API_URL + "Product/UpdateProduct", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ProductId: productId,
          FixedProductItems: fixedProductItems,
          UnFixedProductItems: unFixedProductItems,
          ProductIngredients: selectedIngredients,
          ProductUnrequiredIngredients: unrequiredIngredients,
          AddOns: addons,  
          Sizes:selectedSizes             
        }),
      });
      let result = await response.json();
      
      if (response.status === 200) {
        navigate("/Product")
        if(productImageToDb !== ""){
          const formData = new FormData();
          formData.append("Image", productImageToDb);
          formData.append("FolderName", "Product");
  
          const resImage = await axios.post(
            Variables.API_URL + "Media/UploadImageToServer",
            formData
          );
          if (resImage.data !== "") {
            // insert in media
            let responseMedia = await fetch(
              Variables.API_URL + "Media/InsertMedia",
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  ImageTitle: "",
                  AltText: "",
                  Description: "",
                  Caption: "",
                  Url: resImage.data,
                }),
              }
            );
            let resultInsertMedia = await responseMedia.json();
            if (responseMedia.status === 200) {
              let responsePath = await fetch(
                Variables.API_URL + "Product/UpdateProductMedia/" + productId,
                {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    ProductImage: resultInsertMedia,
                  }),
                }
              );
              let resultInsertPath = await responsePath.json();
            }
          }
        }
        

        toast.success(result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Please Fill All Required Inputs", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const HandleSubmit = () => {
    if (productId === 0) {
      HandleInsert();
    } else {
      HandleUpdate();
    }
  };

  useEffect(() => {
    GerIngredients();
    GetSizes()
    GetMenus();
    GetProductDetails();
  }, []);


  return (
    <>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Manage Product</h4>
              </div>
              <div>
                <Button
                  variant="secondary"
                  onClick={() => navigate("/Product")}
                >
                  Cancel
                </Button>
                {"  "}
                <Button variant="btn buttonMeatcity" onClick={HandleSubmit}>
                  Submit
                </Button>
              </div>
            </Card.Header>
            {loader === true ? (
              <CustomSnipper />
            ) : (
              <Card.Body>
                <div className="bd-example">
                  <Row>
                    {unFixedProductItems.map((data, i) => (
                      <Col lg="6" md="6" xs="12" key={i}>
                        <Card style={{ backgroundColor: "aliceblue" }}>
                          <Card.Header style={{ backgroundColor: "aliceblue" }}>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                <h4 className="px-3">
                                  Language {data.LanguageName}
                                </h4>
                              </div>
                              <div className="d-flex align-items-center justify-content-end"></div>
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <br />
                            <br />
                            <Form className="d-grid gap-card">
                              <Form.Group className="mb-2">
                                <Form.Label htmlFor="fname">
                                  Product Name :
                                </Form.Label>
                                <InputGroup>
                                  <FormControl
                                    type="text"
                                    placeholder={
                                      "Product Name in " + data.LanguageName
                                    }
                                    id={"Name" + i}
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon3"
                                    value={data.Name}
                                    onChange={(e) => HandleNameChange(e, i)}
                                  />
                                  <InputGroup.Text
                                    as="span"
                                    className="input-group-text"
                                    id="Name"
                                  >
                                    {data.LanguageSuffix}
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>

                              <Form.Group className="mb-2">
                                <Form.Label htmlFor="fname">
                                  Product Description :
                                </Form.Label>
                                <InputGroup>
                                  <FormControl
                                    type="text"
                                    placeholder={
                                      "Product Decription in " +
                                      data.LanguageName
                                    }
                                    id={"Description" + i}
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon3"
                                    value={data.Description}
                                    onChange={(e) =>
                                      HandleDescriptionChange(e, i)
                                    }
                                  />
                                </InputGroup>
                              </Form.Group>
                            </Form>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                  <hr />
                  <Row>
                    <Form.Group className="col-md-6 form-group">
                      <Form.Label htmlFor="fname">Menu Section:</Form.Label>
                      <select
                        className="form-control"
                        name="MenuId"
                        id="MenuId"
                        value={fixedProductItems.MenuId}
                        onChange={HandleInputFixedItems}
                      >
                        <option key={0} value={0}>
                          -- Select Menu Section --
                        </option>
                        {menus.map((x) => {
                          return (
                            <option key={x.MenuId} value={x.MenuId}>
                              {x.MenuName}
                            </option>
                          );
                        })}
                      </select>
                    </Form.Group>

                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">Ingredients</Form.Label>
                      <Multiselect
                        options={ingredients} // Options to display in the dropdown
                        selectedValues={selectedIngredients} // Preselected value to persist in dropdown
                        displayValue="Name" // Property name to display in the dropdown options
                        placeholder="Ingredients"
                        id="Ingredients"
                        name="Ingredients"
                        onSelect={handleSelectIngredient}
                        onRemove={handleSelectIngredient}
                      />
                    </Form.Group>

                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">
                        Unrequired Ingredients
                      </Form.Label>
                      <Multiselect
                        options={selectedIngredients} // Options to display in the dropdown
                        selectedValues={unrequiredIngredients} // Preselected value to persist in dropdown
                        displayValue="Name" // Property name to display in the dropdown options
                        placeholder="Unrequired Ingredients"
                        id="UnrequiredIngredients"
                        name="UnrequiredIngredients"
                        onSelect={handleSelectUnrequiredIngredient}
                        onRemove={handleSelectUnrequiredIngredient}
                      />
                    </Form.Group>

                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">AddOns</Form.Label>
                      <Multiselect
                        options={ingredients} // Options to display in the dropdown
                        selectedValues={addons} // Preselected value to persist in dropdown
                        displayValue="Name" // Property name to display in the dropdown options
                        placeholder="AddOns"
                        id="AddOns"
                        name="AddOns"
                        onSelect={handleSelectAddOns}
                        onRemove={handleSelectAddOns}
                      />
                     
                      {addons.length!=0?(
                        <>
                         <br />
                         <br />
                         <Row>
                    <Col className="Col-Text-Center">
                      <h5 className="mb-3">Add Ons Extra Price</h5>
                    </Col>
                  </Row>
                  
                  <br />
                  <br />
                  </>
                  ):null}


                  <Row>
                    {addons.map((data, i) => (
                      <Col lg="6" md="12" key={i}>
                        <Row>
                          <Form.Group className="col-md-6 form-group">
                            <Form.Control
                              id="IngredientName"
                              name="IngredientName"
                              placeholder="Ingredient Name"
                              type="text"
                              value={data.Name}
                              readOnly
                            />
                          </Form.Group>

                          <Form.Group className="col-md-6 form-group">
                            <Form.Control
                              id={"ExtraPrice" + i}
                              name={"ExtraPrice" + i}
                              placeholder="Extra Price"
                              type="number"
                              value={data.ExtraPrice}
                              onChange={(e) => handleAddonsExtraPrice(e, i)}
                            />
                          </Form.Group>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                    </Form.Group>
                    <Form.Group className="col-md-12 form-group">
                      <Form.Label htmlFor="fname">Sizes</Form.Label>
                      <Multiselect
                        options={sizes} // Options to display in the dropdown
                        selectedValues={selectedSizes} // Preselected value to persist in dropdown
                        displayValue="Name" // Property name to display in the dropdown options
                        placeholder="Size"
                        id="Size"
                        name="Size"
                        onSelect={handleSelectSizes}
                        onRemove={handleSelectSizes}
                      />
                       {selectedSizes.length!=0?(
                       <>
                       <br />
                  <br />
                  <Row>
                    <Col className="Col-Text-Center">
                      <h5 className="mb-3">Product Size Extra Price</h5>
                    </Col>
                  </Row>
                        <br />
                  <br />
                  </>
                  ):null}
                  <Row>
                    {selectedSizes.map((data, i) => (
                      <Col lg="6" md="12" key={i}>
                        <Row>
                          <Form.Group className="col-md-6 form-group">
                            <Form.Control
                              id="Name"
                              name="Name"
                              placeholder="Size Name"
                              type="text"
                              value={data.Name}
                              readOnly
                            />
                          </Form.Group>

                          <Form.Group className="col-md-6 form-group">
                            <Form.Control
                              id={"SizePrice" + i}
                              name={"SizePrice" + i}
                              placeholder="Extra Price"
                              type="number"
                              value={data.SizePrice}
                              onChange={(e) => handleSizesPrice(e, i)}
                            />
                          </Form.Group>
                        </Row>
                      </Col>
                    ))}
                  </Row>

                    </Form.Group>
                  </Row>

                  <br />
                  {selectedSizes.length==0?(
                    <>
                  <Row>
                    <Col className="Col-Text-Center">
                      <h5 className="mb-3">Product Price</h5>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                 
                    <Form.Group className="col-md-3 form-group">
                      <Form.Label htmlFor="fname">Regular Price :</Form.Label>
                      <Form.Control
                        id="RegularPrice"
                        name="RegularPrice"
                        placeholder="Regular Price"
                        type="number"
                        value={fixedProductItems.RegularPrice}
                        onChange={(e) => HandleInputFixedItems(e)}
                      />
                      
                    </Form.Group>
                    
                
                    

                    {fixedProductItems.IsSaleSchedule === true && (
                      <>
                        <Form.Group className="col-md-3 form-group">
                          <Form.Label htmlFor="fname">Sale Price :</Form.Label>
                          <Form.Control
                            id="SalePrice"
                            name="SalePrice"
                            placeholder="Sale Price"
                            type="number"
                            value={fixedProductItems.SalePrice}
                            onChange={(e) => HandleInputFixedItems(e)}
                          />
                          
                        </Form.Group>
                        

                        <Form.Group className="col-md-3 form-group">
                          <Form.Label htmlFor="fname">
                            Start Sale Price :
                          </Form.Label>
                          <Form.Control
                            id="SaleStartDate"
                            name="SaleStartDate"
                            placeholder="To .... YYYY-MM-DD"
                            type="datetime-local"
                            disabled={
                              fixedProductItems.HasSalePrice === false
                                ? true
                                : false
                            }
                            value={
                              fixedProductItems.HasSalePrice === false
                                ? ""
                                : fixedProductItems.SaleStartDate
                            }
                            onChange={(e) => HandleInputFixedItems(e)}
                          />
                        </Form.Group>

                        <Form.Group className="col-md-3 form-group">
                          <Form.Label htmlFor="fname">
                            End Sale Price :
                          </Form.Label>
                          <Form.Control
                            id="SaleEndDate"
                            name="SaleEndDate"
                            placeholder="To .... YYYY-MM-DD"
                            type="datetime-local"
                            disabled={
                              fixedProductItems.HasSalePrice === false
                                ? true
                                : false
                            }
                            value={
                              fixedProductItems.HasSalePrice === false
                                ? ""
                                : fixedProductItems.SaleEndDate
                            }
                            onChange={(e) => HandleInputFixedItems(e)}
                          />
                        </Form.Group>
                      </>
                    )}

                    <Form.Group className="col-md-3 form-group">
                      <Form.Label htmlFor="fname">.</Form.Label>
                      <Form.Check className="mb-3 form-check">
                        <FormCheck.Input
                          type="checkbox"
                          name="IsSaleSchedule"
                          id="IsSaleSchedule"
                          value={fixedProductItems.IsSaleSchedule}
                          checked={fixedProductItems.IsSaleSchedule}
                          onChange={(e) => HandleHasSalePrice(e)}
                        />
                        <FormCheck.Label className="" htmlFor="exampleCheck1">
                          Has Sale Sale
                        </FormCheck.Label>
                      </Form.Check>
                    </Form.Group>



                  </Row>
                  </>
):null}
                  <br />
                  <Row>
                    <Col className="Col-Text-Center">
                      <h5 className="mb-3">Product Image</h5>
                    </Col>
                  </Row>

                  <Row>
                    <Form.Group className="col-md-12 form-group">
                      <div className="mb-3">
                        <Form.Label className="" htmlFor="customFile">
                          Image
                        </Form.Label>
                        <Form.Control
                          id="Image"
                          name="Image"
                          placeholder="Image"
                          type="file"
                          accept="image/*"
                          onChange={SetProductImage}
                        />
                      </div>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col className="allignCenter">

                      {productImage === "" ? (
                        <div>
                          <img src={Variables.ImagePath + fixedProductItems.ProductImageUrl}
                            className="w-100 shadow-1-strong rounded mb-4 img-200 image-log"
                            alt="Product Image"
                          />
                        </div>
                      ) : (
                        <div>
                          <img
                            src={productImage}
                            className="w-100 shadow-1-strong rounded mb-4 img-200 image-log"
                            alt="Please Select Image"
                          />
                        </div>
                      )}
                      {/* {productImage === "" ? (
                        <div>
                          <img
                            src={productImage}
                            className="w-100 shadow-1-strong rounded mb-4 img-200 image-log"
                            alt="Product Image"
                          />
                        </div>
                      ) : productImagePath !== "" &&
                        productImagePath !== null ? (
                        <div>
                          <img
                            src={Variables.imagePath + productImagePath}
                            className="w-100 shadow-1-strong rounded mb-4 img-200 image-log"
                            alt="Product Image"
                          />
                        </div>
                      ) : null} */}
                    </Col>
                  </Row>
                  <br />
                  <br />
               
                 
                


                </div>
              </Card.Body>
            )}
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default ManageProduct;
