import React, { useState, useContext,useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";
import {AiOutlineBranches} from 'react-icons/ai';
import {MdDashboard,MdFastfood,MdPerson} from 'react-icons/md';
import {RiProductHuntLine} from 'react-icons/ri';
import {BiFoodMenu,BiSlider} from 'react-icons/bi';
import {IoBagCheckOutline} from 'react-icons/io5';
import {FcSettings} from 'react-icons/fc';
import {CgSize} from 'react-icons/cg';
import {MdSlideshow} from 'react-icons/md';
import {GiNotebook} from 'react-icons/gi'
import Util from "../../../../Classes/Util";
function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

 
  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = () => {
  const [permissions,setPermissions]=useState({})
  //location
  let location = useLocation();

  useEffect(() => {
    if(localStorage.getItem("Permissions")!=null){
      setPermissions(Util.decrypt(localStorage.getItem("Permissions")))
    };
  }, []);

  return (
    <>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
   {permissions.Dashboard && (
        <li className="nav-item">
          <Link
            className={`${location.pathname === "/Dashboard" ? "active" : ""} nav-link `}
            aria-current="page"
            to="/Dashboard"
            onClick={() => {}}
          >
            <i className="icon">
            <MdDashboard/>
            </i>
            <span className="item-name">Dashboard</span>
          </Link>
        </li>
        )}
        
        <li>
          <hr className="hr-horizontal" />
        </li>
        {permissions.ViewBranches && (
        <li className="nav-item">
          <Link
            className={`${location.pathname === "/Branch" ? "active" : ""} nav-link `}
            aria-current="page"
            to="/Branch"
            onClick={() => {}}
          >
            <i className="icon">
           <AiOutlineBranches/>
            </i>
            <span className="item-name">Branch</span>
          </Link>
        </li>
        )}
{permissions.ViewIngredients && (
        <li className="nav-item">
          <Link
            className={`${location.pathname === "/Ingredients" ? "active" : ""} nav-link `}
            aria-current="page"
            to="/Ingredients"
            onClick={() => {}}
          >
            <i className="icon">
              <MdFastfood/>
            </i>
            <span className="item-name">Ingredients</span>
          </Link>
        </li>
        )}
{permissions.ViewSizes && (
        <li className="nav-item">
          <Link
            className={`${location.pathname === "/Sizes" ? "active" : ""} nav-link `}
            aria-current="page"
            to="/Sizes"
            onClick={() => {}}
          >
            <i className="icon">
 <CgSize/>
            </i>
            <span className="item-name">Size</span>
          </Link>
        </li>
        )}
        {permissions.ViewProducts && (
        <li className="nav-item">
          <Link
            className={`${location.pathname === "/Product" ? "active" : ""} nav-link `}
            aria-current="page"
            to="/Product"
            onClick={() => {}}
          >
            <i className="icon">
            <RiProductHuntLine/>
            </i>
            <span className="item-name">Product</span>
          </Link>
        </li>
        )}
{permissions.ViewMenus && (
        <li className="nav-item">
          <Link
            className={`${location.pathname === "/Menus" ? "active" : ""} nav-link `}
            aria-current="page"
            to="/Menus"
            onClick={() => {}}
          >
            <i className="icon">
           <BiFoodMenu/>
            </i>
            <span className="item-name">Menus</span>
          </Link>
        </li>
)}
{permissions.ViewOrders && (
        <li className="nav-item">
          <Link
            className={`${location.pathname === "/Orders" ? "active" : ""} nav-link `}
            aria-current="page"
            to="/Orders"
            onClick={() => {}}
          >
            <i className="icon">
            <IoBagCheckOutline/>
            </i>
            <span className="item-name">Orders</span>
          </Link>
        </li>
)}
{permissions.ViewCustomers && (
        <li className="nav-item">
          <Link
            className={`${location.pathname === "/Customer" ? "active" : ""} nav-link `}
            aria-current="page"
            to="/Customer"
            onClick={() => {}}
          >
            <i className="icon">
          <MdPerson/>
            </i>
            <span className="item-name">Customer</span>
          </Link>
        </li>
        )}
        <li className="nav-item">
          <Link
            className={`${location.pathname === "/Sliders" ? "active" : ""} nav-link `}
            aria-current="page"
            to="/Sliders"
            onClick={() => {}}
          >
            <i className="icon"><BiSlider/></i>
            <span className="item-name"> Sliders</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`${location.pathname === "/Slides" ? "active" : ""} nav-link `}
            aria-current="page"
            to="/Slides"
            onClick={() => {}}
          >
            <i className="icon"><MdSlideshow/></i>
            <span className="item-name"> Slides</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`${location.pathname === "/BookingTypes" ? "active" : ""} nav-link `}
            aria-current="page"
            to="/BookingTypes"
            onClick={() => {}}
          >
            <i className="icon"><GiNotebook/></i>
            <span className="item-name"> Booking Types </span>
          </Link>
        </li>
        
{permissions.ViewSettings && (
        <li className="nav-item">
          <Link
            className={`${location.pathname === "/Settings" ? "active" : ""} nav-link `}
            aria-current="page"
            to="/Settings"
            onClick={() => {}}
          >
            <i className="icon"><FcSettings/></i>
            <span className="item-name"> Settings</span>
          </Link>
        </li>
        

        )}


        
      </Accordion>
    </>
  );
};

export default VerticalNav;
