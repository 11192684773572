import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate, Link } from "react-router-dom";
import { Variables } from "../../../variables/Variables";
import { BiEdit, BiShowAlt, BiHide } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import { BsClipboardMinus, BsClipboardCheck } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";
import ReactTooltip from "react-tooltip";
import CustomSnipper from "../../../components/customSnipper";
import Util from "../../../Classes/Util";
const ListOfLanguages = () => {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [languages, setlanguages] = useState([]);
  const [filteredlanguages, setFilteredlanguages] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  //Publish/Unpublish languages
  const TooglePublish = async (LanguageId) => {
    let response = await fetch(
      Variables.API_URL + "Language/TogglePublish/" + LanguageId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    if (response.ok === false) {
      toast.error(result);
    } else {
      toast.success(result);
      setSearch("");
      Getlanguages();
    }
  };

  //set default/notDefault  of stock
  const ToogleIsDefault = async (LanguageId) => {
    let response = await fetch(
      Variables.API_URL + "Language/ToogleIsDefault/" + LanguageId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    if (response.ok === false) {
      toast.error(result);
    } else {
      toast.success(result);
      Getlanguages();
      setSearch("");
    }
  };
  //fetch languages

  const Getlanguages = async () => {
    setLoader(true);
    let response = await fetch(Variables.API_URL + "Language/GetLanguages", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Variables.API_URL,
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setlanguages(result);
      setFilteredlanguages(result);
      setLoader(false);
    }
  };
  //handle Search by  Name
  const handleSearch = (e) => {
    setSearch(e.target.value);
    let value = e.target.value.toLowerCase();
    let result = [];
    if (value === "") {
      result = languages;
    } else {
      result = languages.filter((child) =>
        child.Title.toLowerCase().includes(value)
      );
    }

    setFilteredlanguages(result);
  };

  //Pagination
  const paginationComponentOptions = {
    rowsPerPageText: "View Records",
    rangeSeparatorText: "out",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };
  //languages columns

  const languagesColumns = [
    {
      name: "#",
      selector: (row) => row.LanguageId,
      sortable: true,
    },
    {
      name: "Language Name",
      selector: (row) => row.Title,
      sortable: true,
    },
    {
      name: "Suffix",
      selector: (row) => row.Suffix,
      sortable: true,
    },

    {
      name: "Default",
      selector: (row) => (
        <div>{row.IsDefault ? <FcCheckmark /> : <AiOutlineClose />}</div>
      ),
      sortable: true,
    },
    {
      name: "Publish",
      selector: (row) => (
        <span
          className={
            row.IsPublished === false ? "badge bg-danger" : "badge bg-success"
          }
        >
          {row.IsPublished === true ? "Published" : "Unpublished"}
        </span>
      ),
      sortable: true,
    },

    {
      name: "Actions",
      selector: (row) => (
        <div>
          {permissions.UpdateLanguage && (
            <Link
              className="btn btn-sm btn-icon "
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title="Edit"
              to={"/Languages/" + row.LanguageId}
            >
              <span className="btn-inner">
                <BiEdit data-tip={"Manage " + row.Title} />
              </span>
              <ReactTooltip place="bottom" className="tooltip" effect="solid" />
            </Link>
          )}
          {permissions.ToggleDefaultLanguage && (
            <button
              className="btn  btn-icon  mr-5"
              onClick={() => ToogleIsDefault(row.LanguageId)}
            >
              {row.IsDefault ? (
                <BsClipboardMinus data-tip="Not Default" />
              ) : (
                <BsClipboardCheck data-tip="Set Default" />
              )}
            </button>
          )}
          {permissions.TogglePublishLanguage && (
            <button
              className="btn  btn-icon ml-5"
              onClick={() => TooglePublish(row.LanguageId)}
            >
              {row.IsPublished ? (
                <BiHide data-tip="Unpublish" />
              ) : (
                <BiShowAlt data-tip="Publish" />
              )}
            </button>
          )}
        </div>
      ),
    },
  ];
  //#region Use Effect
  useEffect(() => {
    Getlanguages();
  }, []);

  //#endregion Use Effect
  return (
    <>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">languages List</h4>
              </div>
              {permissions.AddLanguage && (
                <Button
                  variant="btn buttonMeatcity"
                  onClick={() => navigate("/Languages/0")}
                >
                  Add New Language
                </Button>
              )}
            </Card.Header>

            <Card.Body>
              <Row style={{ marginBottom: "20px" }}>
                <Col sm="6" md="4" lg="4" className="text-right">
                  <input
                    type="text"
                    onChange={(e) => handleSearch(e)}
                    className="form-control"
                    autoFocus={true}
                    placeholder="Search By Name"
                    value={search}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  {loader ? (
                    <CustomSnipper />
                  ) : (
                    <DataTable
                      columns={languagesColumns}
                      data={filteredlanguages}
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                    />
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default ListOfLanguages;
