import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Tab,
  Nav,
  Modal,
  Form,
  InputGroup,
  FormControl,
  FormCheck,
} from "react-bootstrap";
import CustomSnipper from "../../components/customSnipper";
import { useNavigate, Link, Navigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Variables } from "../../variables/Variables";
import axios from "axios";

function ManageIngredient() {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  let { IngredientId } = useParams();

  const [IngredientDetails, setIngredientDetails] = useState([
    {
      Name: "",
      IngredientLanguageId: 0,
      LanguageId: 0,
      LanguageName: "",
      LanguageSuffix: "",
    },
  ]);

  // Get Ingredient Details
  const GetIngredientDetails = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL + "Ingredient/GetIngredient/" + IngredientId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setIngredientDetails(result.IngredientLanguages);
      setLoader(false);
    }
  };

  // Get Languages To Add
  const GetLanguagesToAdd = async () => {
    setLoader(true);
    let response = await fetch(
      Variables.API_URL + "Ingredient/GetLanguagesToAddIngredient",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      setIngredientDetails(result);
      setLoader(false);
    }
  };

  // Handle name change
  const HandleNameChange = (e, index) => {
    var detailsCopy = IngredientDetails;
    var langArray = [...detailsCopy];
    var toUpdate = langArray[index];
    delete langArray[index];
    toUpdate["Name"] = e.target.value;
    langArray[index] = toUpdate;

    setIngredientDetails(langArray);
  };

  // Is Valid To Add
  function IsValid() {
    var hasError = false;

    var counter = 0;
    IngredientDetails.forEach((element) => {
      if (element.Name === "" || element.Name === null) {
        var ele = document.getElementById("Name" + counter);
        ele.classList.add("is-invalid");
        hasError = true;
      }
      counter++;
    });

    if (hasError) {
      return false;
    } else {
      return true;
    }
  }

  const handleSubmit = async () => {
    debugger
    if (IsValid()) {
      if (IngredientId == 0) {
        let response = await fetch(
          Variables.API_URL + "Ingredient/AddIngredient",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Variables.HostAPI,
            },
            body: JSON.stringify({
              IngredientDetails:   IngredientDetails
            }),
          }
        );
        let result = await response.json();
        if (response.ok === false) {
          toast.error(result);
        } else {
          toast.success(result);
          setTimeout(() => {
            navigate("/Ingredients");
          }, 1000);
        }
      } else {
        let response = await fetch(
          Variables.API_URL + "Ingredient/UpdateIngredient/" + IngredientId,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Variables.HostAPI,
            },
            body: JSON.stringify(
              IngredientDetails.map((item) => ({
                Name: item.Name,
                IngredientLanguageId: item.IngredientLanguageId,
                LanguageId: item.LanguageId,
              }))
            ),
          }
        );
        let result = await response.json();
        if (response.ok === false) {
          toast.error(result);
        } else {
          toast.success(result);
          setTimeout(() => {
            navigate("/Ingredients");
          }, 1000);
        }
      }
    } else {
      toast.error("Please Fill All Required Fields");
    }
  };

  useEffect(() => {
    if (IngredientId != 0) {
      GetIngredientDetails();
    } else {
      GetLanguagesToAdd();
    }
  }, []);

  return (
    <>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title"> Manage Ingredient</h4>
              </div>
              <div>
                <Button
                  variant="secondary"
                  onClick={() => navigate("/Ingredients")}
                >
                  Cancel
                </Button>
                {"  "}
                <Button
                  variant="btn buttonMeatcity"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              </div>
            </Card.Header>
            {loader === true ? (
              <CustomSnipper />
            ) : (
              <Card.Body>
                <div className="bd-example">
                  <Row>
                    {IngredientDetails.map((data, i) => (
                      <Col lg="6" md="6" xs="12" key={i}>
                        <Card style={{ backgroundColor: "aliceblue" }}>
                          <Card.Header style={{ backgroundColor: "aliceblue" }}>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                <h4 className="px-3">
                                  Language {data.LanguageName}
                                </h4>
                              </div>
                              <div className="d-flex align-items-center justify-content-end"></div>
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <br />
                            <br />
                            <Form className="d-grid gap-card">
                              <Form.Group className="mb-2">
                                <Form.Label htmlFor="fname">
                                  Ingredient Name :
                                </Form.Label>
                                <InputGroup>
                                  <FormControl
                                    type="text"
                                    placeholder={
                                      "Ingredient Name in " + data.LanguageName
                                    }
                                    id={"Name" + i}
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon3"
                                    value={data.Name}
                                    onChange={(e) => HandleNameChange(e, i)}
                                  />
                                  <InputGroup.Text
                                    as="span"
                                    className="input-group-text"
                                    id="name"
                                  >
                                    {data.LanguageSuffix}
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form.Group>
                            </Form>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}{" "}
                  </Row>
                </div>
              </Card.Body>
            )}
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default ManageIngredient;
