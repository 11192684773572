import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import CustomSnipper from "../../components/customSnipper";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Variables } from "../../variables/Variables";
import { BiEdit, BiShowAlt, BiHide } from "react-icons/bi";
import ReactTooltip from "react-tooltip";
import DataTable from "react-data-table-component";
import Util from "../../Classes/Util";
function MenusList() {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [menus, setMenus] = useState([]);
  const [filteredMenus, setFilteredMenus] = useState([]);
  const [publishModel, setPublishModel] = useState(0);
  const [search, setSearch] = useState("");
  // Get Menus
  const GetMenus = async () => {
    setLoader(true);
    let response = await fetch(Variables.API_URL + "Menu/GetMenusForTable", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    if (response.status === 200) {
      setMenus(result);
      setFilteredMenus(result);
    }
    setLoader(false);
  };

  // Publish - Unpublish menu
  const PublishUnpublishMenu = async (mId) => {
    let response = await fetch(
      Variables.API_URL + "Menu/TooglePublishMenu/" + mId,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setPublishModel(0);
      GetMenus();
    } else {
      toast.error(result, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  //handle Search by  Name
  const handleSearch = (e) => {
    setSearch(e.target.value);
    let value = e.target.value.toLowerCase();
    let result = [];
    if (value === "") {
      result = menus;
    } else {
      result = menus.filter((child) =>
        child.Name.toLowerCase().includes(value)
      );
    }

    setFilteredMenus(result);
  };

  //Pagination
  const paginationComponentOptions = {
    rowsPerPageText: "View Records",
    rangeSeparatorText: "out",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  //menus columns

  const menusColumns = [
    {
      name: "#",
      selector: (row) => row.MenuId,
      sortable: true,
    },
    {
      name: "Menu Name",
      selector: (row) => row.Name,
      sortable: true,
    },

    {
      name: "Publish",
      selector: (row) => (
        <span
          className={
            row.IsPublished === false ? "badge bg-danger" : "badge bg-success"
          }
        >
          {row.IsPublished === true ? "Published" : "Unpublished"}
        </span>
      ),
      sortable: true,
    },

    {
      name: "Actions",
      selector: (row) => (
        <div>
          {permissions.UpdateMenu && (
            <Link
              className="btn btn-sm btn-icon "
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title="Edit"
              to={"/Menus/" + row.MenuId}
            >
              <span className="btn-inner">
                <BiEdit data-tip={"Manage " + row.Name} />
              </span>
            </Link>
          )}
          {permissions.PublishMenu && (
            <Link
              className="btn btn-sm btn-icon"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title="Publish"
              to="#"
              onClick={() => setPublishModel(row.MenuId)}
            >
              {row.IsPublished ? (
                <BiHide data-tip="Unpublish" />
              ) : (
                <BiShowAlt data-tip="pPublish" />
              )}
            </Link>
          )}
          <Modal
            show={row.MenuId === publishModel}
            onHide={() => setPublishModel(0)}
          >
            <Modal.Header closeButton>
              <Modal.Title as="h5">
                {row.IsPublished ? "Unpublished Menu" : "Published Menu"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to{" "}
              {row.IsPublished ? "Unpublish : " : "Publish : "}
              <b>{row.name}</b>?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setPublishModel(0)}>
                Close
              </Button>
              <Button
                variant="btn buttonMeatcity"
                onClick={() => PublishUnpublishMenu(row.MenuId)}
              >
                Save changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];

  useEffect(() => {
    GetMenus();
  }, []);

  return (
    <>
      <Row>
        <Col xl="12" lg="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Menus List</h4>
              </div>
              {permissions.AddMenu && (
                <Button
                  variant="btn buttonMeatcity"
                  onClick={() => navigate("/Menus/0")}
                >
                  Add New Menu
                </Button>
              )}
            </Card.Header>

            <Card.Body>
              <Row style={{ marginBottom: "20px" }}>
                <Col sm="6" md="4" lg="4" className="text-right">
                  <input
                    type="text"
                    onChange={(e) => handleSearch(e)}
                    className="form-control"
                    autoFocus={true}
                    placeholder="Search By Name"
                    value={search}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  {loader ? (
                    <CustomSnipper />
                  ) : (
                    <DataTable
                      columns={menusColumns}
                      data={filteredMenus}
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                    />
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default MenusList;
